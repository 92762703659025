<div class="page-wraper">

  <!-- HEADER START -->
  <app-header1></app-header1>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">
    <!-- INNER PAGE BANNER -->
    <app-banner1 [data]="banner"></app-banner1>
    <!-- INNER PAGE BANNER END -->

    <!-- SECTION CONTENT START -->
    <div class="section-full small-device  p-t80">
      <div class="container">
        <div class="project-detail-outer">

          <!-- COVER IMAGE START -->
          <div class="row" *ngIf="event.cover != ''">
            <div class="col-md-12">
              <div class="project-detail-pic m-b30">
                <div class="wt-media">
                  <img src="{{event.cover}}" alt="">
                </div>
              </div>
            </div>
          </div>
          <!-- COVER IMAGE END -->

          <div class="project-detail-containt">
            <div class="bg-white text-black">
              <div class="row">
                <div class="col-md-3 col-sm-3">
                  <div class="product-block">
                    <div class="row">
                      <div class="col-md-6 col-sm-6 m-b30" *ngFor="let meta of event.meta">
                        <h3 class="text-uppercase font-weight-600 m-b10">{{meta.name}}</h3>
                        <p>{{meta.value}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="p-t0">
                    <ul class="social-icons social-square social-lg social-darkest m-b0">
                      <li *ngIf="event.social.facebook != ''"><a href="{{event.social.facebook}}"
                          class="fa fa-facebook"></a></li>
                      <li *ngIf="event.social.twitter != ''"><a href="{{event.social.twitter}}"
                          class="fa fa-twitter"></a></li>
                      <li *ngIf="event.social.youtube != ''"><a href="{{event.social.youtube}}"
                          class="fa fa-youtube"></a></li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-9 col-sm-9">
                  <h2>{{event.title}}</h2>
                  <p><strong>{{event.subtitle}}</strong></p>
                  <div [innerHtml]="event.message | safe"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- SECTION CONTENT END  -->

      <!-- SECTION CONTENT START -->
      <div class="section-full small-device p-tb80 bg-gray">

        <!-- GALLERY CONTENT START -->
        <div class="container-fluid">
          <!-- GALLERY CONTENT START -->
          <div class="portfolio-wrap mfp-gallery work-grid row clearfix">
            <!-- COLUMNS 1 -->
            <div class="masonry-item col-lg-3  col-md-4 col-sm-6 m-b30" *ngFor="let image of event.images">
              <div class="wt-box work-hover-content">
                <div class="wt-thum-bx  img-center-icon">
                  <img src="{{image}}" alt="">
                </div>
              </div>
            </div>
          </div>
          <!-- GALLERY CONTENT END -->
        </div>
        <!-- GALLERY CONTENT END -->
      </div>
      <!-- SECTION CONTENT END  -->

    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-footer1></app-footer1>
    <!-- FOOTER END -->

    <!-- BUTTON TOP START -->
    <app-scroll-to-top></app-scroll-to-top>

  </div>

  <!-- LOADING AREA START ===== -->
  <app-loading></app-loading>