import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';

type NewsItem = {
  post_title: string;
  description: string;
  title: string;
  subtitle: string;
  category: string;
  message: string;
  blog_image: string;
  cover: string;
  images: string;
  // Generated properties start
  id: string;
  post_date: string;
  // Generated properties end
};

@Component({
  selector: 'app-admin-news',
  templateUrl: './admin-news.component.html',
  styleUrls: ['./admin-news.component.css']
})

export class AdminNewsComponent implements OnInit {
  private getAllNewsUrl = 'https://beta.crestcebu.com/news-query.php'; // update me!
  private createPostUrl = 'https://beta.crestcebu.com/create-post.php'; // update me!
  private deletePostUrl = 'https://beta.crestcebu.com/delete-post.php'; // update me!
  private updatePostUrl = 'https://beta.crestcebu.com/update-post.php'; // update me!
  newsGridSection = [];
  newsForm: FormGroup;
  passwordForm: FormGroup;
  selectedPostId = '';
  // UI tab name
  postActionText = 'Create';

  constructor(private http: HttpClient, private authService: AuthService,
              private router: Router) { }

  populateGrid() {
    this.http.get(this.getAllNewsUrl).subscribe(
      (data: NewsItem[]) => {
        this.newsGridSection = data;
      },
      error => {
        console.log('error');
      }
    );
  }

  ngOnInit(): void {
    this.populateGrid();
    // Init form
    this.newsForm = new FormGroup({
      post_title: new FormControl(''),
      description: new FormControl(''),
      title: new FormControl(''),
      subtitle: new FormControl(''),
      category: new FormControl(''),
      message: new FormControl(''),
      blog_image: new FormControl(''),
      cover: new FormControl(''),
      images: new FormControl('')
    });
    this.passwordForm = new FormGroup({
      password: new FormControl(''),
      confirm_password: new FormControl(''),
    });
  }

  onFormSubmit() {
    if (this.postActionText === 'Update') {
      this.onUpdatePost();
    } else {
      this.onCreatePost();
    }
  }

  onCreateSelected() {
    this.postActionText = 'Create';
  }

  onCreatePost() {
    const payload = {
      post_title: this.newsForm.get('post_title').value,
      description: this.newsForm.get('description').value,
      title: this.newsForm.get('title').value,
      subtitle: this.newsForm.get('subtitle').value,
      category: this.newsForm.get('category').value,
      message: this.newsForm.get('message').value,
      blog_image: this.newsForm.get('blog_image').value,
      cover: this.newsForm.get('cover').value,
      images: this.newsForm.get('images').value
    };

    this.http.post(this.createPostUrl, JSON.stringify(payload), {responseType: 'text'}).subscribe(
      (res) => { alert(res); window.location.reload(); }
    );
  }

  onDeletePopupShow(postId: string) {
    this.selectedPostId = postId;
  }

  onDeletePost() {
    this.http.post(this.deletePostUrl, JSON.stringify(this.selectedPostId), {responseType: 'text'}).subscribe(
      (res) => { alert(res); window.location.reload(); }
    );
  }

  onPostSelected(postId: string) {
    this.selectedPostId = postId;
    const item = this.newsGridSection.find(e => e.id === postId);
    this.newsForm.get('post_title').setValue(item.post_title);
    this.newsForm.get('description').setValue(item.description);
    this.newsForm.get('title').setValue(item.title);
    this.newsForm.get('subtitle').setValue(item.subtitle);
    this.newsForm.get('category').setValue(item.category);
    this.newsForm.get('message').setValue(item.message);
    this.newsForm.get('blog_image').setValue(item.blog_image);
    this.newsForm.get('cover').setValue(item.cover);
    this.newsForm.get('images').setValue(item.images);
    this.postActionText = 'Update';
  }

  onUpdatePost() {
    const payload = {
      post_id: this.selectedPostId,
      post_title: this.newsForm.get('post_title').value,
      description: this.newsForm.get('description').value,
      title: this.newsForm.get('title').value,
      subtitle: this.newsForm.get('subtitle').value,
      category: this.newsForm.get('category').value,
      message: this.newsForm.get('message').value,
      blog_image: this.newsForm.get('blog_image').value,
      cover: this.newsForm.get('cover').value,
      images: this.newsForm.get('images').value
    };

    this.http.post(this.updatePostUrl, JSON.stringify(payload), {responseType: 'text'}).subscribe(
      (res) => { alert(res); window.location.reload(); }
    );
  }

  onChangePassword() {
    if (this.authService.isUserLoggedIn()) {
      const password = this.passwordForm.get('password').value;
      const confPassword = this.passwordForm.get('confirm_password').value;
      if (password === confPassword) {
        this.authService.updatePassword(password);
        this.authService.logout();
        this.router.navigate(['pages/admin/login']);
      } else {
        alert("Passwords did not match.");
      }
    } else {
      alert('You are not logged in!');
    }
  }

}
