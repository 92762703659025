import { Injectable } from '@angular/core';
import { PwManagerService } from './pw-manager.service';
import { TokenManagerService } from './token-manager.service';

@Injectable()
export class AuthService {
    constructor(private pwManager: PwManagerService,
                private tokenManager: TokenManagerService) {
    }

    login(password: string) {
        if (this.pwManager.isPasswordMatch(password)) {
            this.tokenManager.setToken(password);
        }
    }

    isUserLoggedIn(): boolean {
        return this.tokenManager.hasToken();
    }

    logout(): void{
        this.tokenManager.removeToken();
    }

    updatePassword(newpw: string) {
        this.pwManager.savePassword(newpw);
    }

    resetPassword() {
        this.pwManager.resetPassword();
    }
}
