import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Home1Component } from './home1/home1.component';
import { Contact1Component } from './pages/contact1/contact1.component';
import { NewsGridComponent } from './blog/news-grid/news-grid.component';
import { ProjectDetailComponent } from './pages/project-detail/project-detail.component';
import { PostGalleryComponent } from './blog/post-gallery/post-gallery.component';
import { AdminNewsComponent } from './pages/admin-news/admin-news.component';
import { AdminLoginComponent } from './pages/admin-login/admin-login.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { CreditsPageComponent } from './pages/credits-page/credits-page.component';

const routes: Routes = [
                {path: '', component: Home1Component},
                {path: 'index', component: Home1Component},
                {path: 'index1', component: Home1Component},
                {path: 'index-1', component: Home1Component},
                {path: 'home', component: Home1Component},
                {path: 'home1', component: Home1Component},
                {path: 'home-1', component: Home1Component},
                {path: 'home-1', component: Home1Component},
                {path: 'contact', component: Contact1Component},
                {path: 'news', component: NewsGridComponent},
                {path: 'news/:id', component: ProjectDetailComponent},
                {path: 'careers', component: PostGalleryComponent},
                {path: 'pages/admin', component: AdminNewsComponent, canActivate : [AuthGuardService]},
                {path: 'pages/admin/login', component: AdminLoginComponent},
                {path: 'credits', component: CreditsPageComponent}
              ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
