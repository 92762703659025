import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';

type BranchDetails = {
  name: string;
  contactNumbers: string;
  supportEmail: string;
  address: string;
  gMapURL: string;
};

@Component({
  selector: 'app-contact1',
  templateUrl: './contact1.component.html',
  styleUrls: ['./contact1.component.css']
})

export class Contact1Component implements OnInit {
  public branchID = '';
  bDetails;
  statusIndex = 0;
  statusMsg = [
    "Email not yet submitted.",
    "Sending email. Please wait.",
    "Email successfully sent.",
    "Email sending failed."
  ];
  submitBtnText = ["Submit", "Submit", "Sent", "Submit"];

  senderEmail = '';
  senderName = '';
  senderBody = '';

  banner: any = {
    pagetitle: "Contact Us",
    bg_image: "4.jpg",
    title: "Contact Us",
  };


  private sendContactURL = 'https://beta.crestcebu.com/send-contact.php';  // update me!

  constructor(private router: Router, private http: HttpClient) {
    // Receive and store the branch ID passed by the previous page
    this.branchID = this.router.getCurrentNavigation().extras.state?.branchID || 1;
  }

  ngOnInit(): void {
    this.bDetails = this.getBranchDetails();
    // Set the branch details to page elements
    const iframe =  document.getElementById('gmap-frame') as HTMLIFrameElement;
    iframe.contentWindow.location.replace(this.bDetails.gMapURL);
    const phone =  document.getElementById('details-phone') as InnerHTML;
    phone.innerHTML = this.bDetails.contactNumbers;
    const address =  document.getElementById('details-address') as InnerHTML;
    address.innerHTML = this.bDetails.address;
  }

  getBranchDetails(): BranchDetails {
    // Branch IDs:
    //  (1) - Head Office (Default)
    //  (2) - Manila
    //  (3) - Davao
    //  (4) - CDO
    switch (String(this.branchID)) {
      case "1":
        return {
          name: "Head Office (Cebu)",
          contactNumbers: "(032) 266-0685<br>(032) 266-0686<br>(032) 260-2820<br>(032) 262-3945",
          supportEmail: "query.cebu@crestcebu.com",
          address: "Door 203, PMM Bldg. M.J. Cuenco Avenue, Mabolo, Cebu City, 6000",
          gMapURL: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d981.3391755885956!\
          2d123.9122845!3d10.3133115!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe5384599318f57bd!\
          2sCrest%20Forwarder%20Inc.!5e0!3m2!1sen!2sca!4v1630614354345!5m2!1sen!2sca"
        };
      case "2":
        return {
          name: "Manila Branch",
          contactNumbers: "(02) 254-6447<br>(02) 523-3031<br>(02) 523-1732<br>(02) 879-1631",
          supportEmail: "query.manila@crestcebu.com",
          address: "Unit-D Marian Bldg., 2556 G. Del Pilar St. Cor. P. Ocampo Sr. Malate Manila",
          gMapURL: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d965.3925248781428!\
          2d121.0018664!3d14.5665595!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6bf02ebf9483b0f9!\
          2sCREST%20Forwarder%20Inc.!5e0!3m2!1sen!2sca!4v1630614520315!5m2!1sen!2sca"
        };
      case "3":
        return {
          name: "Davao Branch",
          contactNumbers: "(082) 392-5109",
          supportEmail: "query.davao@crestcebu.com",
          address: "Door 7 PGJ Realty Bldg., Brgy. Vicente Hizon Davao City, 8000",
          gMapURL: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1979.563888984397!\
          2d125.6486664!3d7.1111876!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x32f96c1f184a0f03%3A0xaf792f8b64ff3334!\
          2sCrest%20Forwarder%2C%20Inc.!5e0!3m2!1sen!2sca!4v1630678460260!5m2!1sen!2sca"
        };
      case "4":
        return {
          name: "Cagayan De Oro Branch",
          contactNumbers: "(088) 333-7126",
          supportEmail: "query.cdo@crestcebu.com",
          address: "Door 203, Waterside Living Complex, Julio Pacana Licoan, Cagayan de Oro City, 9000",
          gMapURL: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1973.0508797443763!\
          2d124.6495358!3d8.4894863!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x32fff2e7a30a851b%3A0x858ac540592952f4!\
          2sCrest%20Forwarder%20Inc.!5e0!3m2!1sen!2sca!4v1630678708675!5m2!1sen!2sca"
        };
      default:
        return {
          name: "Head Office (Cebu)",
          contactNumbers: "(032) 266-0685<br>(032) 266-0686<br>(032) 260-2820<br>(032) 262-3945",
          supportEmail: "query.cebu@crestcebu.com",
          address: "Door 203, PMM Bldg. M.J. Cuenco Avenue, Mabolo, Cebu City, 6000",
          gMapURL: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d981.3391755885956!\
          2d123.9122845!3d10.3133115!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe5384599318f57bd!\
          2sCrest%20Forwarder%20Inc.!5e0!3m2!1sen!2sca!4v1630614354345!5m2!1sen!2sca"
        };
    }
  }

  emailSending() {
    this.statusIndex = 1;

  }

  emailSent(result) {
    this.statusIndex = 2;
    console.log('result: ', result);
  }

  emailFailed(error) {
    this.statusIndex = 3;
    this.statusMsg[this.statusIndex] += ` ${error}`;
    console.log('Error sending email: ', error);
  }

  onSubmit() {
    this.emailSending();


    const emailPayload = {
      senderEmail: this.senderEmail,
      senderName: this.senderName,
      receiverEmail: this.bDetails.supportEmail,
      receiverName: 'Crest Forwarder Inc. <' + this.bDetails.name + '>',
      subject: `Contact-us from - ${this.senderName} <${this.senderEmail}>`,
      body: this.senderBody
    };

    this.http.post(this.sendContactURL, JSON.stringify(emailPayload), {responseType: 'text'}).subscribe(res => {
      if (res === 'SUCCESS') {
        this.emailSent("Contact Us email has been successfully sent.");
      } else {
        this.emailFailed(`Failed to send Contact Us email. Error: ${res}`);
      }
    });
  }
}
