import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

declare var jQuery: any;

type NewsItem = {
  post_title: string;
  description: string;
  title: string;
  subtitle: string;
  category: string;
  message: string;
  blog_image: string;
  cover: string;
  images: string;
  // Generated properties start
  id: string;
  post_date: string;
  // Generated properties end
};

@Component({
  selector: 'app-news-grid',
  templateUrl: './news-grid.component.html',
  styleUrls: ['./news-grid.component.css']
})

export class NewsGridComponent implements OnInit {
  basehref = window.origin;
  // private url = this.basehref+'/news-query.php';  // update me!
  private url = 'https://beta.crestcebu.com/news-query.php';  // update me! Prod env


  public activePagination = '';
  newsGridSection = [];

  banner: any = {
    pagetitle: "News and Events",
    bg_image: "2.jpg",
    title: "News and Events",
  };

  newsPagination = [
    {
      title: "Events",
      filter: ".event",
      category: "event",
      href: "#",
      isActive: ""
    },
    {
      title: "Milestone",
      filter: ".milestone",
      category: "milestone",
      href: "#",
      isActive: ""
    },
    {
      title: "Hiring",
      filter: ".hiring",
      category: "hiring",
      href: "#",
      isActive: ""
    }
  ];

  constructor(private router: Router, private http: HttpClient, private route: ActivatedRoute) {

    if (this.router.getCurrentNavigation().extras.state) {
      this.setActiveFilter(this.router.getCurrentNavigation().extras.state?.activePagination);
    }

    this.http.get(this.url).subscribe(
      (data: NewsItem[]) => {
        this.newsGridSection = data;
      },
      error => {
        console.log('error');
      }
    );

  }

  ngOnInit(): void {
  }

  setActiveFilter(category: string) {
    this.activePagination = category;
  }
}
