import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import * as CryptoJS from 'crypto-js';

// key that is used to access the data in local storage
const AUTH_KEY = 'local_auth';

@Injectable()
export class TokenManagerService {
    constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {
    }

    hasToken() {
        const authKey = this.storage.get(AUTH_KEY) || "";
        if (authKey === "") {
            return false;
        } else {
            return true;
        }
    }

    setToken(token: string) {
        // insert token to local storage -- (fyi - token == encrypted password)
        this.storage.set(AUTH_KEY, CryptoJS.AES.encrypt(token.trim(), token.trim()).toString());
    }

    removeToken() {
        this.storage.clear();
    }
}
