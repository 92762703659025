<div class="page-wraper"> 
    
    <!-- HEADER START -->
    <app-header1></app-header1>
    <!-- HEADER END -->
    
    <!-- CONTENT START -->
    <div class="page-content">
        <!-- INNER PAGE BANNER -->
        <app-banner1 [data]="banner"></app-banner1>
        <!-- INNER PAGE BANNER END -->
        
  <!-- SECTION CONTENT START -->
        <div class="section-full small-device p-tb80 bg-gray">
          <div class="container">
                <!-- PAGINATION START -->
                <div class="filter-wrap p-tb50 text-center">
                    <ul class="masonry-filter link-style text-uppercase">
                            <li *ngFor="let pagination of newsPagination" class="{{pagination.isActive}}" (click)="setActiveFilter(pagination.category)">
                                <a id="{{pagination.title}}" [attr.data-filter]="pagination.filter">{{pagination.title}}</a>
                            </li>
                    </ul>
                </div>
                <!-- PAGINATION END -->
          </div>
            <!-- GALLERY CONTENT START -->
            <div class="mfp-gallery news-grid clearfix">
                    <div class="row">
                        <!-- COLUMNS 1 -->
                        <div class="masonry-item {{item.category}} col-lg-4 col-md-6 col-sm-6 m-b30 blog-grid-1 transition duration-600 transform" *ngFor="let item of newsGridSection" [ngClass]="{'isHidden': (activePagination == '' ? false: item.category != activePagination), 'isNotHidden': !(activePagination == '' ? false: item.category != activePagination)}">
                            <div class="wt-img-effect " >
                                <img src="{{item.blog_image}}" alt="">
                            </div>
                            <div class="wt-post-info  bg-white">
                                <div class="wt-post-meta ">
                                    <ul>
                                        <li class="post-date" [innerHTML]="item.post_date"></li>
                                    </ul>
                                </div>                                        
                                <div class="wt-post-title ">
                                    <h3 class="post-title"><a routerLink="/news/{{item.id}}" class=" font-weight-600 m-t0">{{item.post_title}}</a></h3>
                                </div>
                                <div class="wt-post-text">
                                    <p>{{item.description}}</p> 
                                </div>
                                <a routerLink="/news/{{item.id}}" class="btn-half site-button site-btn-effect button-md"><span>{{item.read_more_text}}</span></a>
                            </div>
                        </div>
                    </div>
            </div>
            <!-- GALLERY CONTENT END -->
        
        </div>
        <!-- SECTION CONTENT END  -->

    </div>
    <!-- CONTENT END -->
               
    <!-- FOOTER START -->
    <app-footer1></app-footer1>
    <!-- FOOTER END -->

    <!-- BUTTON TOP START -->
<app-scroll-to-top></app-scroll-to-top>

</div>

<!-- LOADING AREA START ===== -->
<app-loading></app-loading>