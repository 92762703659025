import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-links',
  templateUrl: './nav-links.component.html',
  styleUrls: ['./nav-links.component.css']
})
export class NavLinksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  closeNav() {
    try {
      document.getElementById('full-menu-close').click();
    } catch (err) {
    }
  }

  changeFilter(filter) {
    try {
      document.getElementById(filter).click();
      this.closeNav();
    } catch (err) {
    }
  }
}
