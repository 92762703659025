import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {
  form: FormGroup;

  constructor(private router: Router,
              private authService: AuthService) { }

  ngOnInit(): void {
    if (this.authService.isUserLoggedIn()) {
      this.router.navigate(['pages/admin']);
    }
    // Init form
    this.form = new FormGroup({
      passwordField: new FormControl('')
    });
  }

  onSubmit() {
    this.authService.login(this.form.get('passwordField').value);
    if (this.authService.isUserLoggedIn()) {
      this.router.navigate(['pages/admin']);
    } else {
      alert('Wrong password!');
    }

    this.form.reset();
  }

  onPasswordReset() {
    this.authService.resetPassword();
  }
}
