import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home1',
  templateUrl: './home1.component.html',
  styleUrls: ['./home1.component.css']
})
export class Home1Component implements OnInit {

  equipmentsSection = [
    {
      section_title: "",
      section_heading: "Equipments",
      section_description: "",
      class_val: "",
      section_class: "",
      items: [
        {
          image: "pic1.jpg",
          catgegory: "asdf",
          heading: "Vans",
          title: "Vans",
          description: ``,
          url: "project-detail",
        },
        {
          image: "pic2.jpg",
          catgegory: "",
          heading: "Forklifts",
          title: "Forklifts",
          description: ``,
          url: "project-detail",
        },
        {
          image: "pic3.jpg",
          catgegory: "",
          heading: "Prime Mover",
          title: "Prime Mover",
          description: ``,
          url: "project-detail",
        }
      ]
    }
  ];

  facilitiesSection = [
    {
      section_title: "",
      section_heading: "Facilities",
      section_description: "",
      class_val: "",
      section_class: "",
      items: [
        {
          image: "pic6.jpg",
          catgegory: "",
          heading: "Warehouse",
          title: "Warehouse",
          description: `<b>Cebu Warehouse</b><br>
          Arellano Blvd. Brgy. San Roque, Cebu City<br>
          (032) 345-1891<br>
          <br>
          <b>Navotas Warehouse</b><br>
          Taliba Street, Brgy. San Rafael Village, Navotas, Metro Manila<br>
          (02) 7000-0897, 8376-1463`,
          url: "project-detail",
        },
        {
          image: "pic4.jpg",
          catgegory: "",
          heading: "SAP",
          title: "SAP",
          description: ``,
          url: "project-detail",
        },
        {
          image: "pic5.jpg",
          catgegory: "",
          heading: "Trucking Yard",
          title: "Trucking Yard",
          description: `<b>Cebu Warehouse</b><br>
          Arellano Blvd, Brgy. San Roque, Cebu City<br>
          (032) 345-1891<br>
          <br>
          <b>Navotas Warehouse</b><br>
          #31 Benjamin Street, Brgy. San Rafael Village, Navotas, Metro Manila<br>
          (02) 7000-0897, 8376-1463`,
          url: "project-detail",
        }
      ]
    }
  ];
  ourSpecializationSection = [
    {
      section_title: "Our specialization",
      section_heading: "Why choose us",
      section_description: "",
      class_val: "",
      section_class: "",
      section_image: "",
      items: [
        {
          icon: "budget",
          catgegory: "",
          heading: "",
          title: "Cost ",
          description: "We",
          url: "",
        }
      ]
    }
  ];
  aboutUs = [
    {
      section_title: "Know more",
      section_heading: "About Us",
      section_description: "",
    }
  ];
  blogs = [
    {
      section_title: "Latest",
      section_heading: "News",
      section_description: "asdf",
      items: [
        {
          id: "event",
          title: "",
          blog_image: "latest-news-1.png",
          post_date: "",
          post_author: "",
          post_title: "Events",
          read_more_text: "Read More",
          read_more_link: "news",
        },
        {
          id: "milestone",
          title: "",
          blog_image: "latest-news-2.png",
          post_date: "",
          post_author: "",
          post_title: "Milestone",
          read_more_text: "Read More",
          read_more_link: "news",
        },
        {
          id: "hiring",
          title: "",
          blog_image: "latest-news-3.png",
          post_date: "",
          post_author: "",
          post_title: "Hiring",
          read_more_text: "Read More",
          read_more_link: "news",
        },
      ]
    }
  ];

  contactus: any = {
    pagetitle: "",
    bg_image: "4.jpg",
    title: "Contact Us",
  };

  constructor() { }

  ngOnInit(): void {
  }

}
