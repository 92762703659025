<div class="page-wraper">
  <!-- HEADER START -->
  <app-header1></app-header1>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">
    <div class="container">
      <div class="section-content">
        <div class="wt-tabs tabs-default">
          <ul class="nav nav-tabs">
            <li class="active"><a data-toggle="tab" href="#main" aria-expanded="false">Dashboard</a></li>
            <li class=""><a data-toggle="tab" href="#postform" aria-expanded="false">{{postActionText}} Post</a></li>
            <li class=""><a data-toggle="tab" href="#settings" aria-expanded="true">Settings</a></li>
          </ul>
          <div class="tab-content">
            <!-- LIST OF POSTS -->
            <div id="main" class="tab-pane active">
              <br><br>
              <div class="portfolio-wrap mfp-gallery news-grid clearfix">
                <div class="row">
                  <div class="blog-grid-1 cat-1 col-lg-4 col-md-6 col-sm-6 m-b30 masonry-item ng-star-inserted" *ngFor="let item of newsGridSection">
                    <div class="wt-img-effect"><img alt="" src="{{item.blog_image}}"></div>
                    <div class="wt-post-info bg-white">
                      <div class="wt-post-meta">
                        <ul>
                          <li class="post-date">
                            <strong>{{item.post_date}}</strong>
                          </li>
                          <li class="post-author">
                            <i class="fa fa-user"></i>
                            <a href="javascript:void(0);">By <span>Admin</span></a>
                          </li>
                        </ul>
                      </div>
                      <div class="wt-post-title">
                        <h3 class="post-title">
                          <a class="font-weight-600 m-t0">{{item.post_title}}</a>
                        </h3>
                      </div>
                      <div class="wt-post-text">
                        <p>{{item.description}}</p>
                      </div>
                      <div class="section-content">
                        <button type="button"
                          class="m-b15 site-button site-btn-effect m-r15 text-uppercase letter-spacing-2"
                          (click)="onPostSelected(item.id)" data-toggle="tab" data-target="#postform">
                          <span>Update</span>
                        </button>
                        <button type="button"
                          class="m-b15 site-button-secondry site-btn-effect text-uppercase letter-spacing-2"
                          (click)="onDeletePopupShow(item.id)" data-toggle="modal" data-target="#deleteNewsModal">
                          <span>Delete</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- LIST OF POSTS END -->
            <!-- POST FORM -->
            <div id="postform" class="tab-pane">
              <form class="form-horizontal mb-lg ng-untouched ng-pristine ng-valid" [formGroup]="newsForm" (ngSubmit)="onFormSubmit()">
                <p><label for="input">Headline</label><input type="text" class="form-control"
                    formControlName="post_title" placeholder="Thumbnail title"></p>
                <p><label for="input">Description</label><input type="text" class="form-control"
                    formControlName="description" placeholder="Thumbnail subtitle"></p>
                <p><label for="input">Title</label><input type="text" class="form-control" formControlName="title"
                    placeholder="Blog post title"></p>
                <p><label for="input">Subtitle</label><input type="text" class="form-control" formControlName="subtitle"
                    placeholder="Blog post subtitle"></p>
                <p>
                  <label for="select">Category</label>
                  <select class="form-control" formControlName="category">
                    <option value="">Choose...</option>
                    <optgroup label="Categories">
                      <option value="event">Event</option>
                      <option value="hiring">Hiring</option>
                      <option value="milestone">Milestone</option>
                    </optgroup>
                  </select>
                </p>
                <p><label for="textarea">Body</label>
                   <a href="https://htmlg.com/html-editor/" style="float: right;" class="site-button site-btn-effect pull-right" role="button">Open HTML Editor</a>
                   <textarea class="form-control" formControlName="message" rows="25"
                    placeholder="You may also use HTML tags for defining text with a special meaning."></textarea></p>
                <p><label for="input">Thumbnail Photo</label><input type="text" class="form-control"
                    formControlName="blog_image" placeholder="Photo URL"></p>
                <p><label for="input">Main Photo</label><input type="text" class="form-control" formControlName="cover"
                    placeholder="Photo URL"></p>
                <p><label for="input">Blog Photos</label><textarea class="form-control" rows="5"
                    formControlName="images"
                    placeholder="Photo URL. Use a vertical bar ( | ) to separate multiple photos."></textarea></p>
                <span class="input-group-btn">
                  <button class="site-button site-btn-effect pull-right" type="submit">{{postActionText}}</button>
                </span>
              </form>
            </div>
            <!-- POST FORM END -->
            <!-- SETTINGS -->
            <div id="settings" class="tab-pane">
              <div class="m-b30">
                <div class="section-head">
                    <h2 class="text-uppercase font-weight-900 m-b5">Administrator</h2>
                    <div class="wt-separator-outer">
                        <div class="wt-separator bg-primary"></div>
                    </div>
                </div>
                <div class="section-content">
                    <a href="https://analytics.google.com/"
                        class="site-button site-btn-effect outline black button-app m-r15 m-b15">
                        <span>Navigate to
                            <strong class="text-center">Google Analytics</strong>
                        </span>
                    </a>
                    <a href="https://tagmanager.google.com/"
                        class="site-button site-btn-effect outline black button-app m-r15 m-b15">
                        <span>Navigate to
                            <strong class="text-center">Tag Manager</strong>
                        </span>
                    </a>
                    <a href="#changePassword" class="site-button site-btn-effect outline black button-app m-r15 m-b15"
                        data-toggle="collapse">
                        <span>Update Account
                            <strong class="text-center">Password</strong>
                        </span>
                    </a>
                </div>
                <div class="collapse" id="changePassword">
                    <div class="card card-body">
                      <form [formGroup]="passwordForm" (ngSubmit)="onChangePassword()" class="form-horizontal mb-lg ng-untouched ng-pristine ng-valid">
                        <div class="form-group mt-lg">
                            <label class="col-sm-3 control-label">Password</label>
                            <div class="col-sm-9">
                                <input name="name" formControlName="password" placeholder="New password" required="" type="password" class="form-control">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-3 control-label">Confirm Password</label>
                            <div class="col-sm-9">
                                <input name="email" formControlName="confirm_password" placeholder="Confirm password" required="" type="password" class="form-control">
                            </div>
                        </div>
                        <span class="input-group-btn text-center">
                          <button class="site-button site-btn-effect" type="submit">Update Password</button>
                        </span>
                      </form>
                    </div>
                </div>
              </div>
            </div>
            <!-- SETTINGS END -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- CONTENT END -->

  <!-- MODAL DELETE START -->
  <div id="deleteNewsModal" class="modal fade" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-secondry">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title text-white">Delete Post</h4>
        </div>
        <div class="modal-body">
          <p>Are you sure you want to delete this post?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="site-button site-btn-effect button-sm letter-spacing-2 text-uppercase"
            data-dismiss="modal" (click)="onDeletePost()"><span>Yes</span>
          </button>
          &nbsp;
          <button type="button" class="site-button site-btn-effect button-sm letter-spacing-2 text-uppercase m-r15"
            data-dismiss="modal"><span>No</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL DELETE END -->

  <footer class="container-fluid text-center">
    <p>© 2021 Crest Forwarder Inc. Designed By Ben & Vince (Credits)</p>
  </footer>
  <!-- BUTTON TOP START -->
  <app-scroll-to-top></app-scroll-to-top>
  <!-- BUTTON TOP START END -->
</div>

<!-- LOADING AREA START ===== -->
<app-loading></app-loading>