<div class="wt-bnr-inr overlay-wraper bg-parallax bg-top-center"  data-stellar-background-ratio="0.5"  style="background-image:url(assets/images/banner/{{data?.bg_image}});">
  <div class="overlay-main bg-black opacity-07"></div>
    <div class="container">
        <div class="wt-bnr-inr-entry">
          <div class="banner-title-outer">
              <div class="banner-title-name">
                <h2 class="text-white  font-80 font-weight-900">{{data?.title}}</h2>
                </div>
            </div>
            <!-- BREADCRUMB ROW -->                            
            
                <div *ngIf="data.pagetitle!=''">
                    <ul class="wt-breadcrumb breadcrumb-style-2">
                        <li><a routerLink="/index">Home</a></li>
                        <li>{{data?.pagetitle}}</li>
                    </ul>
                </div>
            
            <!-- BREADCRUMB ROW END -->                        
        </div>
    </div>
</div>