import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';

declare var jQuery: any;

@Component({
  selector: 'app-header2',
  templateUrl: './header2.component.html',
  styleUrls: ['./header2.component.css']
})
export class Header2Component implements OnInit {

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    (function($) {
      setTimeout(function(){
          jQuery(window).on('scroll', function() {
                    const scroll = jQuery(window).scrollTop();
                    if (scroll >= 100) {
                      jQuery(".sticky-header").addClass("color-fill");
                      jQuery(".sticky-header").addClass("is-fixed");
                    } else {
                        jQuery(".sticky-header").removeClass("color-fill");
                        jQuery(".sticky-header").removeClass("is-fixed");
                    }
                  });
          }, 500);
    })(jQuery);
  }

  onLogout() {
    this.authService.logout();
    this.router.navigate(['pages/admin/login']);
  }

}
