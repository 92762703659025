<div class="page-wraper">
      <!-- HEADER START -->
      <app-header1></app-header1>
      <!-- HEADER END -->

      <!-- CONTENT START -->
      <div class="page-content">
          <!-- INNER PAGE BANNER -->
          <app-banner1 [data]="banner"></app-banner1>
          <!-- INNER PAGE BANNER END -->
          <!-- SECTION CONTENTG START -->
          <div class="section-full small-device  p-tb80">
              <!-- LOCATION BLOCK-->
              <div class="container">
                  <div class="gmap-outline m-b50">
                    <iframe id="gmap-frame" src=""
                       width="100%"
                       height="450"
                       style="border:0;"
                       allowfullscreen="true"
                       loading="lazy">
                    </iframe>
                  </div>
              </div>
              <!-- CONTACT FORM -->
              <div class="section-content overlay-wraper bg-repeat bg-primary overflow-hide" style="background-image:url(assets/images/background/bg7.png);">
                    <div class="overlay-main opacity-05"></div>
                    <div class="container">
                          <!-- CONTACT FORM-->
                          <div class="row">
                              <div class="col-md-8 col-sm-6">

                                  <form class="contact-form" (ngSubmit)="onSubmit()" #contactForm="ngForm">
                                      <div class="contact-one">
                                          <!-- TITLE START -->
                                          <div class="section-head text-left">
                                              <h2 class="text-uppercase font-weight-900 m-b5">Get In Touch</h2>
                                          </div>
                                          <!-- TITLE END -->
                                          <div class="form-group">
                                              <input name="name" type="text" required class="form-control" placeholder="Name" [(ngModel)]="senderName" #name="ngModel" [attr.disabled]="statusIndex > 0 ? '' : null">
                                          </div>
                                     
                                          <div class="form-group">
                                              <input name="email" type="email" class="form-control" required placeholder="Email" [(ngModel)]="senderEmail"  [attr.disabled]="statusIndex > 0 ? '' : null">
                                          </div>
                                     
                                          <div class="form-group">
                                              <textarea name="message" rows="4" class="form-control " required placeholder="Message" [(ngModel)]="senderBody"  [attr.disabled]="statusIndex > 0 ? '' : null"></textarea>
                                          </div>
                                      
                                          <div class="text-left">
                                              <button id="submit" type="submit" name="submit" class="btn btn-success" value="Submit" class="site-button site-btn-effect button-lg" data-toggle="modal" data-target="#Default-Modal"  [attr.disabled]="!contactForm.form.valid || statusIndex > 0 ? '' : null">{{submitBtnText[statusIndex]}}</button>
                                          </div>
                                      </div>
                                  </form>

                              </div>
                              <div class="col-md-4 col-sm-6">
                                  <div class="contact-info text-white p-tb50">
                                      <div class="contact-info-inner  bg-dark p-a20">
                                          <!-- TITLE START -->
                                          <div class="section-head text-left">
                                              <h2 class="text-uppercase font-weight-900 m-b5">Contact Info</h2>
                                          </div>
                                          <!-- TITLE END -->
                                          <div class="wt-icon-box-wraper left p-b40">
                                              <div class="icon-xs"><i class="fa fa-phone"></i></div>
                                              <div class="icon-content">
                                                  <h5 class="m-t0 font-weight-500">Phone number</h5>
                                                  <p id="details-phone"></p>
                                              </div>
                                          </div>
                                          <div class="wt-icon-box-wraper left">
                                              <div class="icon-xs"><i class="fa fa-map-marker"></i></div>
                                              <div class="icon-content">
                                                  <h5 class="m-t0 font-weight-500">Address info</h5>
                                                  <p id="details-address"></p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
         </div>
          <!-- SECTION CONTENT END -->
      </div>
      <!-- CONTENT END -->

      <!-- FOOTER START -->
      <app-footer1></app-footer1>
      <!-- FOOTER END -->

      <!-- BUTTON TOP START -->
  <app-scroll-to-top></app-scroll-to-top>
</div>



<!-- MODAL POPUP START -->
  <div id="Default-Modal" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <!-- MODAL CONTENT-->
      <div class="modal-content">
        <div class="modal-header bg-secondry">
          <button type="button" class="close" data-dismiss="modal" *ngIf="statusIndex < 2">&times;</button>
          <h4 class="modal-title text-black">Email status:</h4>
        </div>
        <div class="modal-body">
          <p>{{statusMsg[statusIndex]}}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="site-button site-btn-effect button-sm text-uppercase letter-spacing-2" data-dismiss="modal" *ngIf="statusIndex < 2"><span> Close</span></button>
        </div>
      </div>
    </div>
  </div>
<!-- MODAL POPUP END -->


<!-- LOADING AREA START ===== -->
<app-loading></app-loading>