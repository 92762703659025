<div class="container">
    <form class="form-signin" [formGroup]="form" (ngSubmit)="onSubmit()">
        <h2 class="form-signin-heading">Please sign in</h2>
        <label for="inputPassword" class="sr-only">Password</label>
        <input type="password" id="inputPassword" class="form-control" placeholder="Password" required="" formControlName="passwordField">
        <button class="btn btn-lg btn-success btn-block" type="submit">Sign in</button>
        <br>
        <div class="row text-center">
            <a role="button" href="#" data-toggle="modal" data-target="#forgetPwModal">Reset Password</a>
        </div>
    </form>
    <!-- MODAL PW RESET START -->
    <div id="forgetPwModal" class="modal fade" role="dialog">
        <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header bg-secondry">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title text-white">Password Reset</h4>
            </div>
            <div class="modal-body">
            <p>Your password will be changed. A new password will be sent to cr****************005@gmail.com . Are you sure you want to proceed?</p>
            </div>
            <div class="modal-footer">
            <button type="button" class="site-button site-btn-effect button-sm letter-spacing-2 text-uppercase"
                data-dismiss="modal" (click)="onPasswordReset()"><span>Yes</span>
            </button>
            &nbsp;
            <button type="button" class="site-button site-btn-effect button-sm letter-spacing-2 text-uppercase m-r15"
                data-dismiss="modal"><span>No</span>
            </button>
            </div>
        </div>
        </div>
    </div>
    <!-- MODAL END -->
</div> <!-- /container -->