<div id="welcome_wrapper" class="rev_slider_wrapper fullscreen-container" data-alias="goodnews-header"
  data-source="gallery" style="background:#eeeeee;padding:0px;">
  <div id="welcome" class="rev_slider fullscreenbanner" style="display:none;" data-version="5.4.3.1">
    <ul>

      <!-- SLIDE 1 -->
      <li data-index="rs-901" data-transition="fade" data-slotamount="default" data-hideafterloop="0"
        data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="default"
        data-thumb="assets/images/main-slider/slider1/slide1.jpg" data-rotate="0" data-fstransition="fade"
        data-fsmasterspeed="300" data-fsslotamount="7" data-saveperformance="off" data-title="Slide Title"
        data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6=""
        data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
        <!-- MAIN IMAGE -->
        <img src="assets/images/main-slider/slider1/slide1.jpg" alt=""
          data-lazyload="assets/images/main-slider/slider1/slide1.jpg" data-bgposition="center center"
          data-bgfit="cover" data-bgparallax="4" class="rev-slidebg" data-no-retina>
        <!-- LAYERS -->
        <!-- LAYER NR. 1 [ for overlay ] -->
        <div class="tp-caption tp-resizeme" id="slide-901-layer-0"
          data-x="['left','left','left','left']" data-hoffset="['115','115','0','0']"
          data-y="['top','top','top','top']" data-voffset="['135','140','155','155']"
          data-width="420" data-height="['315','320','280','280']"
          data-whitespace="nowrap" data-type="shape"
          data-responsive_offset="on" data-responsive="on" data-frames='[
              {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
              ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
          data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
          style="z-index: 1;background-color:rgba(0, 0, 0, 0.5);border-color:rgba(0, 0, 0, 0);border-width:0px;border-radius:.25em;">
        </div>

        <!-- LAYER NR. 3 [ Black Box ] -->
        <div class="tp-caption   tp-resizeme" id="slide-901-layer-3" data-x="['left','left','left','left']"
          data-hoffset="['150','150','30','30']" data-y="['top','top','top','top']"
          data-voffset="['200','200','200','200']" data-fontsize="['20','20','20','20']"
          data-lineheight="['56','56','56','50']" data-width="['700','700','96%','96%']"
          data-height="['none','none','none','none']" data-whitespace="['normal','normal','normal','normal']"
          data-type="text" data-responsive_offset="on" data-frames='[
              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
              ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
          data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 13; 
              white-space: normal; 
              font-weight: 600;
              color:#fff;
              border-width:0px;">
          <div class="text-primary text-uppercase"><span class="light-number">01</span>Service</div>
        </div>



        <!-- LAYER NR. 4 [ for title ] -->
        <div class="tp-caption   tp-resizeme" id="slide-901-layer-4" data-x="['left','left','left','left']"
          data-hoffset="['150','150','30','30']" data-y="['top','top','top','top']"
          data-voffset="['300','300','300','300']" data-fontsize="['56','56','38','28']"
          data-lineheight="['66','66','48','38']" data-width="['700','700','96%','96%']"
          data-height="['none','none','none','none']" data-whitespace="['normal','normal','normal','normal']"
          data-type="text" data-responsive_offset="on" data-frames='[
              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
              ]' data-textAlign="['left','left','left','left']" data-paddingtop="[5,5,5,5]"
          data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 13; 
              white-space: normal; 
              font-weight: 400;
              color:#fff;
              border-width:0px;">
          <div style="font-family: 'Oswald', sans-serif;text-transform:uppercase;">
            <span class="text-white"> Sea Freight</span>
          </div>

        </div>

        <!-- LAYER NR. 5 [ for paragraph] -->
        <div class="tp-caption  tp-resizeme" id="slide-901-layer-5" data-x="['left','left','left','left']"
          data-hoffset="['150','150','30','30']" data-y="['top','top','top','top']"
          data-voffset="['390','390','390','390']" data-fontsize="['16','16','16','14']"
          data-lineheight="['30','30','30','22']" data-width="['600','600','90%','90%']"
          data-height="['none','none','none','none']" data-whitespace="['normal','normal','normal','normal']"
          data-type="text" data-responsive_offset="on" data-frames='[
              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
              ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
          data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 13; 
              font-weight: 500; 
              color:#fff;
              border-width:0px;">
          <span style="font-family: 'Roboto', sans-serif">Domestic / International Sea Freight forwarding</span>
        </div>

      </li>

      <!-- SLIDE 2 -->
      <li data-index="rs-902" data-transition="fade" data-slotamount="default" data-hideafterloop="0"
        data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="default"
        data-thumb="assets/images/main-slider/slider1/slide2.jpg" data-rotate="0" data-fstransition="fade"
        data-fsmasterspeed="300" data-fsslotamount="7" data-saveperformance="off" data-title="Slide Title" data-param1=""
        data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8=""
        data-param9="" data-param10="" data-description="">
        <!-- MAIN IMAGE -->
        <img src="assets/images/main-slider/slider1/slide2.jpg" alt=""
          data-lazyload="assets/images/main-slider/slider1/slide2.jpg" data-bgposition="center center"
          data-bgfit="cover" data-bgparallax="4" class="rev-slidebg" data-no-retina>
        <!-- LAYERS -->
        <!-- LAYER NR. 1 [ for overlay ] -->
        <div class="tp-caption tp-resizeme" id="slide-902-layer-0"
          data-x="['left','left','left','left']" data-hoffset="['115','115','0','0']"
          data-y="['top','top','top','top']" data-voffset="['135','140','155','155']"
          data-width="420" data-height="['315','320','280','280']"
          data-whitespace="nowrap" data-type="shape"
          data-responsive_offset="on" data-responsive="on" data-frames='[
              {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
              ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
          data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
          style="z-index: 1;background-color:rgba(0, 0, 0, 0.5);border-color:rgba(0, 0, 0, 0);border-width:0px;border-radius:.25em;">
        </div>

        <!-- LAYER NR. 3 [ Black Box ] -->
        <div class="tp-caption   tp-resizeme" id="slide-902-layer-3" data-x="['left','left','left','left']"
          data-hoffset="['150','150','30','30']" data-y="['top','top','top','top']"
          data-voffset="['200','200','200','200']" data-fontsize="['20','20','20','20']"
          data-lineheight="['56','56','56','50']" data-width="['700','700','96%','96%']"
          data-height="['none','none','none','none']" data-whitespace="['normal','normal','normal','normal']"
          data-type="text" data-responsive_offset="on" data-frames='[
              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
              ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
          data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 13; 
              white-space: normal; 
              font-weight: 600;
              color:#fff;
              border-width:0px;">
          <div class="text-primary text-uppercase"><span class="light-number">02</span>Service</div>
        </div>



        <!-- LAYER NR. 4 [ for title ] -->
        <div class="tp-caption   tp-resizeme" id="slide-902-layer-4" data-x="['left','left','left','left']"
          data-hoffset="['150','150','30','30']" data-y="['top','top','top','top']"
          data-voffset="['300','300','300','300']" data-fontsize="['56','56','38','28']"
          data-lineheight="['66','66','48','38']" data-width="['700','700','96%','96%']"
          data-height="['none','none','none','none']" data-whitespace="['normal','normal','normal','normal']"
          data-type="text" data-responsive_offset="on" data-frames='[
              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
              ]' data-textAlign="['left','left','left','left']" data-paddingtop="[5,5,5,5]"
          data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 13; 
              white-space: normal; 
              font-weight: 400;
              color:#fff;
              border-width:0px;">
          <div style="font-family: 'Oswald', sans-serif;text-transform:uppercase;">
            <span class="text-white"> Air Freight</span>
          </div>

        </div>

        <!-- LAYER NR. 5 [ for paragraph] -->
        <div class="tp-caption  tp-resizeme" id="slide-902-layer-5" data-x="['left','left','left','left']"
          data-hoffset="['150','150','30','30']" data-y="['top','top','top','top']"
          data-voffset="['390','390','390','390']" data-fontsize="['16','16','16','14']"
          data-lineheight="['30','30','30','22']" data-width="['600','600','90%','90%']"
          data-height="['none','none','none','none']" data-whitespace="['normal','normal','normal','normal']"
          data-type="text" data-responsive_offset="on" data-frames='[
              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
              ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
          data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 13; 
              font-weight: 500; 
              color:#fff;
              border-width:0px;">
          <span style="font-family: 'Roboto', sans-serif">Domestic / International Air Freight</span>
        </div>

      </li>

      <!-- SLIDE 3 -->
      <li data-index="rs-903" data-transition="fade" data-slotamount="default" data-hideafterloop="0"
        data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="default"
        data-thumb="assets/images/main-slider/slider1/slide3.jpg" data-rotate="0" data-fstransition="fade"
        data-fsmasterspeed="300" data-fsslotamount="7" data-saveperformance="off" data-title="Slide Title" data-param1=""
        data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8=""
        data-param9="" data-param10="" data-description="">
        <!-- MAIN IMAGE -->
        <img src="assets/images/main-slider/slider1/slide3.jpg" alt=""
          data-lazyload="assets/images/main-slider/slider1/slide3.jpg" data-bgposition="center center"
          data-bgfit="cover" data-bgparallax="4" class="rev-slidebg" data-no-retina>
        <!-- LAYERS -->
        <!-- LAYER NR. 1 [ for overlay ] -->
        <div class="tp-caption tp-resizeme" id="slide-903-layer-0"
          data-x="['left','left','left','left']" data-hoffset="['115','115','0','0']"
          data-y="['top','top','top','top']" data-voffset="['135','140','155','155']"
          data-width="420" data-height="['315','320','280','280']"
          data-whitespace="nowrap" data-type="shape"
          data-responsive_offset="on" data-responsive="on" data-frames='[
              {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
              ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
          data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
          style="z-index: 1;background-color:rgba(0, 0, 0, 0.5);border-color:rgba(0, 0, 0, 0);border-width:0px;border-radius:.25em;">
        </div>

        <!-- LAYER NR. 3 [ Black Box ] -->
        <div class="tp-caption   tp-resizeme" id="slide-903-layer-3" data-x="['left','left','left','left']"
          data-hoffset="['150','150','30','30']" data-y="['top','top','top','top']"
          data-voffset="['200','200','200','200']" data-fontsize="['20','20','20','20']"
          data-lineheight="['56','56','56','50']" data-width="['700','700','96%','96%']"
          data-height="['none','none','none','none']" data-whitespace="['normal','normal','normal','normal']"
          data-type="text" data-responsive_offset="on" data-frames='[
              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
              ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
          data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 13; 
              white-space: normal; 
              font-weight: 600;
              color:#fff;
              border-width:0px;">
          <div class="text-primary text-uppercase"><span class="light-number">03</span>Service</div>
        </div>

        <!-- LAYER NR. 4 [ for title ] -->
        <div class="tp-caption   tp-resizeme" id="slide-903-layer-4" data-x="['left','left','left','left']"
          data-hoffset="['150','150','30','30']" data-y="['top','top','top','top']"
          data-voffset="['300','300','300','300']" data-fontsize="['56','56','38','28']"
          data-lineheight="['66','66','48','38']" data-width="['700','700','96%','96%']"
          data-height="['none','none','none','none']" data-whitespace="['normal','normal','normal','normal']"
          data-type="text" data-responsive_offset="on" data-frames='[
              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
              ]' data-textAlign="['left','left','left','left']" data-paddingtop="[5,5,5,5]"
          data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 13; 
              white-space: normal; 
              font-weight: 400;
              color:#fff;
              border-width:0px;">
          <div style="font-family: 'Oswald', sans-serif;text-transform:uppercase;">
            <span class="text-white"> Trucking</span>
          </div>

        </div>

        <!-- LAYER NR. 5 [ for paragraph] -->
        <div class="tp-caption  tp-resizeme" id="slide-903-layer-5" data-x="['left','left','left','left']"
          data-hoffset="['150','150','30','30']" data-y="['top','top','top','top']"
          data-voffset="['390','390','390','390']" data-fontsize="['16','16','16','14']"
          data-lineheight="['30','30','30','22']" data-width="['600','600','90%','90%']"
          data-height="['none','none','none','none']" data-whitespace="['normal','normal','normal','normal']"
          data-type="text" data-responsive_offset="on" data-frames='[
              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
              ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
          data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 13; 
              font-weight: 500; 
              color:#fff;
              border-width:0px;">
          <span style="font-family: 'Roboto', sans-serif">Trucking, Dry Van, and Door-to-Door
            Deliveries</span>
        </div>

      </li>

      <!-- SLIDE 4 -->
      <li data-index="rs-904" data-transition="fade" data-slotamount="default" data-hideafterloop="0"
        data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="default"
        data-thumb="assets/images/main-slider/slider1/slide4.jpg" data-rotate="0" data-fstransition="fade"
        data-fsmasterspeed="300" data-fsslotamount="7" data-saveperformance="off" data-title="Slide Title" data-param1=""
        data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8=""
        data-param9="" data-param10="" data-description="">
        <!-- MAIN IMAGE -->
        <img src="assets/images/main-slider/slider1/slide4.jpg" alt=""
          data-lazyload="assets/images/main-slider/slider1/slide4.jpg" data-bgposition="center center"
          data-bgfit="cover" data-bgparallax="4" class="rev-slidebg" data-no-retina>
        <!-- LAYERS -->
        <!-- LAYER NR. 1 [ for overlay ] -->
        <div class="tp-caption tp-resizeme" id="slide-904-layer-0"
          data-x="['left','left','left','left']" data-hoffset="['115','115','0','0']"
          data-y="['top','top','top','top']" data-voffset="['135','140','155','155']"
          data-width="420" data-height="['315','320','280','280']"
          data-whitespace="nowrap" data-type="shape"
          data-responsive_offset="on" data-responsive="on" data-frames='[
              {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
              ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
          data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
          style="z-index: 1;background-color:rgba(0, 0, 0, 0.5);border-color:rgba(0, 0, 0, 0);border-width:0px;border-radius:.25em;">
        </div>

        <!-- LAYER NR. 3 [ Black Box ] -->
        <div class="tp-caption   tp-resizeme" id="slide-904-layer-3" data-x="['left','left','left','left']"
          data-hoffset="['150','150','30','30']" data-y="['top','top','top','top']"
          data-voffset="['200','200','200','200']" data-fontsize="['20','20','20','20']"
          data-lineheight="['56','56','56','50']" data-width="['700','700','96%','96%']"
          data-height="['none','none','none','none']" data-whitespace="['normal','normal','normal','normal']"
          data-type="text" data-responsive_offset="on" data-frames='[
              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
              ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
          data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 13; 
              white-space: normal; 
              font-weight: 600;
              color:#fff;
              border-width:0px;">
          <div class="text-primary text-uppercase"><span class="light-number">04</span>Service</div>
        </div>



        <!-- LAYER NR. 4 [ for title ] -->
        <div class="tp-caption   tp-resizeme" id="slide-904-layer-4" data-x="['left','left','left','left']"
          data-hoffset="['150','150','30','30']" data-y="['top','top','top','top']"
          data-voffset="['300','300','300','300']" data-fontsize="['56','56','38','28']"
          data-lineheight="['66','66','48','38']" data-width="['700','700','96%','96%']"
          data-height="['none','none','none','none']" data-whitespace="['normal','normal','normal','normal']"
          data-type="text" data-responsive_offset="on" data-frames='[
              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
              ]' data-textAlign="['left','left','left','left']" data-paddingtop="[5,5,5,5]"
          data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 13; 
              white-space: normal; 
              font-weight: 400;
              color:#fff;
              border-width:0px;">
          <div style="font-family: 'Oswald', sans-serif;text-transform:uppercase;">
            <span class="text-white"> Warehousing</span>
          </div>

        </div>

        <!-- LAYER NR. 5 [ for paragraph] -->
        <div class="tp-caption  tp-resizeme" id="slide-904-layer-5" data-x="['left','left','left','left']"
          data-hoffset="['150','150','30','30']" data-y="['top','top','top','top']"
          data-voffset="['390','390','390','390']" data-fontsize="['16','16','16','14']"
          data-lineheight="['30','30','30','22']" data-width="['600','600','90%','90%']"
          data-height="['none','none','none','none']" data-whitespace="['normal','normal','normal','normal']"
          data-type="text" data-responsive_offset="on" data-frames='[
              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
              ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
          data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 13; 
              font-weight: 500; 
              color:#fff;
              border-width:0px;">
          <span style="font-family: 'Roboto', sans-serif">Warehousing and Short-term Storage Management</span>
        </div>

      </li>

    </ul>
  </div>
</div>