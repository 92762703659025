import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class PwManagerService {
    private adminpw = '';
    private pwfile = 'pwconfig'; // Update me!
    private key = CryptoJS.enc.Utf8.parse("79CB0897868E965E");
    private iv = CryptoJS.enc.Utf8.parse("6983A903BA4A0641");
    private pwChars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    private genPwLength = 6;
    private adminEmail = "crestforwarderinc2005@gmail.com";  // Update Me!

    private readPwURL = 'https://beta.crestcebu.com/read-pwd.php'; // update me!
    private savePwURL = 'https://beta.crestcebu.com/update-pwd.php'; // update me!
    private sendPwURL = 'https://beta.crestcebu.com/send-mail.php'; // update me!

    constructor(private http: HttpClient) {
        // Read admin password from file
        this.readPasswordFile();
    }

    readPasswordFile() {
        const payload = {
            file: this.pwfile
        };
        this.http.post(this.readPwURL, JSON.stringify(payload), {responseType: 'text'}).subscribe(
            (res) => { this.adminpw = res; }
        );
    }

    encrypt(data: string) {
        const srcs = CryptoJS.enc.Utf8.parse(data);
        const encrypted = CryptoJS.AES.encrypt(srcs, this.key, {
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.ciphertext.toString().toUpperCase();
    }

    decrypt(data: string) {
        const encryptedHexStr = CryptoJS.enc.Hex.parse(data);
        const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
        const decrypt = CryptoJS.AES.decrypt(srcs, this.key, {
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
        return decryptedStr.toString();
    }

    isPasswordMatch(inputPw: string) {
        const adminPwDecryt = this.decrypt(this.adminpw);
        if (inputPw === adminPwDecryt) {
            return true;
        } else {
            return false;
        }
    }

    savePassword(inputPw: string) {
        const adminPwEncrypt = this.encrypt(inputPw);
        const payload = {
            pw: adminPwEncrypt,
            file: this.pwfile
        };
        this.http.post(this.savePwURL, JSON.stringify(payload), {responseType: 'text'}).subscribe(
            (res) => { alert(res); }
        );
    }

    randomString(length: any, chars: string) {
        let result = '';
        for (let i = length; i > 0; --i) {
          result += chars[Math.round(Math.random() * (chars.length - 1))];
        }
        return result;
    }

    resetPassword() {
        const newPw = this.randomString(this.genPwLength, this.pwChars);
        console.log("Password Reset " +  newPw);  // Remove me!!!
        const emailStr = "Your password for crestcebu.com admin account has been changed. Please login with this new password: " + newPw;
        const payload = {
            receiver: this.adminEmail,
            name: this.adminEmail,
            subject: "Password Reset",
            body: emailStr
        };
        this.http.post(this.sendPwURL, JSON.stringify(payload), {responseType: 'text'}).subscribe(
            (res) => {
                if (res === "SUCCESS") {
                    this.savePassword(newPw);
                } else {
                    console.log("Password reset encountered failure.");
                }
             }
        );
    }
}
