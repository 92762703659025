<div class="loading-area">
  <div class="loading-box"></div>
  <div class="loading-pic">
      <div class="cssload-thecube">
          <div class="cssload-cube cssload-c1"></div>
          <div class="cssload-cube cssload-c2"></div>
          <div class="cssload-cube cssload-c4"></div>
          <div class="cssload-cube cssload-c3"></div>
      </div>
  </div>
</div>