<div class="page-wraper">

  <!-- HEADER START -->
  <app-header1></app-header1>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content ">
    <!-- INNER PAGE BANNER -->
    <app-banner1 [data]="banner"></app-banner1>
    <!-- INNER PAGE BANNER END -->

    <!-- SECTION CONTENT START -->
    <div class="section-full small-device  p-tb80 square_shape1 square_shape3">
      <div class="container">

        <!-- OUR BLOG START -->
        <div class="section-full bg-white">
          <div class="container">

            <!-- TITLE START -->
            <div class="section-head text-left">
              <h2 class="text-uppercase font-45 font-weight-500 m-b5">Join Us!</h2>
              <ul class="career-ul-recepient">
                <li>Interested applicants may send updated resume at:</li>
                <li>E-mail: {{ recepient.email }}</li>
                <li>Contact Number: {{ recepient.contact }}</li>
              </ul>
            </div>
            <hr>
            <!-- TITLE END -->

            <ng-template ngFor let-data [ngForOf]="position" let-datai="index">

              <!-- BLOG START -->
              <div class="blog-post date-style-1 blog-detail text-black">
                <div class="wt-post-title ">
                  <h2 class="post-title">{{data.title}}</h2>
                  <strong *ngIf="data.responsibilities != ''">Job Responsibilities</strong>
                  <p *ngIf="data.responsibilities != ''">{{data.responsibilities}}</p>
                  <strong>Qualifications</strong>
                  <ul class="qualifications">
                    <li *ngFor="let qualification of data.qualifications">{{qualification}}</li>
                  </ul>
                </div>
              </div>
            </ng-template>

            <!-- TITLE START -->
            <hr>
            <div class="section-head text-left">
              <ul class="career-ul-recepient">
                <li>Interested applicants may send updated resume at:</li>
                <li>E-mail: {{ recepient.email }}</li>
                <li>Contact Number: {{ recepient.contact }}</li>
              </ul>
            </div>
            <!-- TITLE END -->
          </div>

        </div>
        <!-- OUR BLOG END -->

      </div>
    </div>
    <!-- SECTION CONTENT END -->

  </div>
  <!-- CONTENT END -->

  <!-- FOOTER START -->
  <app-footer1></app-footer1>
  <!-- FOOTER END -->

  <!-- BUTTON TOP START -->
  <app-scroll-to-top></app-scroll-to-top>

</div>

<!-- LOADING AREA START ===== -->
<app-loading></app-loading>