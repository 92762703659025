<div class="section-full small-device  p-tb80 bg-white  bg-no-repeat bg-bottom-right" style="background-image:url(assets/images/background/bg-4.jpg);">
<ng-template ngFor let-sectionData [ngForOf]="data">
  <div class="container">
  
      <div class="section-content">

        <div class="row">
            <!-- <div class="col-md-2 col-sm-12">
              </div> -->
          <div class="col-md-12 col-sm-12">
                  <!-- TITLE START -->
                  <div class="section-head text-left">
                      <h4 class="text-primary text-uppercase wt-title-small">{{sectionData.section_title}}</h4>
                      <h2 class="text-uppercase font-45 font-weight-500 m-b5">{{sectionData.section_heading}}</h2>
                  </div>
                  <!-- TITLE END -->
            <div class="row">
              <div class="col-md-6 col-sm-6">
                    <div class="wt-icon-box-wraper bg-white m-b30">
                        <div class="icon-lg inline-icon	 m-b50 text-left">
                            <span class="icon-cell text-primary">
                              <i class="fa">
                                  <img src="assets/images/icons/budget.svg" />
                              </i>
                            </span>
                        </div>
                        <div class="icon-content bold-title">
                            <h4 data-title="Efficient" class="wt-tilte text-uppercase font-weight-600 m-b20" ><u>Cost efficient</u></h4>
                            <p>We know how to negotiate with carriers according to your cargo needs. We have a wide-range of connections in different ports all over the nation, and we know how to make things easier for you.</p>
                        </div>
                    </div>
                </div>   
                <div class="col-md-6 col-sm-6">
                      <div class="wt-icon-box-wraper bg-white m-b30">
                          <div class="icon-lg inline-icon	 m-b50 text-left">
                              <span class="icon-cell text-primary">
                                <i class="fa">
                                    <img src="assets/images/icons/route.svg" />
                                </i>
                              </span>
                          </div>
                          <div class="icon-content bold-title">
                              <h4 data-title="Versatility" class="wt-tilte text-uppercase font-weight-600 m-b20" ><u>Versatility</u></h4>
                              <p>We can meet tight deadlines and make sure that everything is going as planned. We know how to act on unforeseen incidents and have the ways to reroute shipments for on-time delivery if needed.</p>
                          </div>
                      </div>
                  </div>   
                  <div class="col-md-6 col-sm-6">
                        <div class="wt-icon-box-wraper bg-white m-b30">
                            <div class="icon-lg inline-icon	 m-b50 text-left">
                                <span class="icon-cell text-primary">
                                  <i class="fa">
                                      <img src="assets/images/icons/diamond.svg" />
                                  </i>
                                </span>
                            </div>
                            <div class="icon-content bold-title">
                                <h4 data-title="Quality" class="wt-tilte text-uppercase font-weight-600 m-b20" ><u>Quality Services</u></h4>
                                <p>We are composed of well-experienced customer service that can manage your entire shipment with obsessed customer care. We makes sure that the delivery of goods is on time, and we communicate well with the carriers and the shippers, to see if everything is going as smoothly as possible.</p>
                            </div>
                        </div>
                    </div>   
                    <div class="col-md-6 col-sm-6">
                          <div class="wt-icon-box-wraper bg-white m-b30">
                              <div class="icon-lg inline-icon	 m-b50 text-left">
                                  <span class="icon-cell text-primary">
                                    <i class="fa">
                                        <img src="assets/images/icons/support.svg" />
                                    </i>
                                  </span>
                              </div>
                              <div class="icon-content bold-title">
                                  <h4 data-title="Support" class="wt-tilte text-uppercase font-weight-600 m-b20" ><u>24/7 customer support</u></h4>
                                  <p>We go the extra mile to offer 24/7 service to clients.</p>
                              </div>
                          </div>
                      </div>                                                                                                 
                  </div>
              </div>
          </div>

      </div>
  </div>
  </ng-template>
</div>   