import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-post-gallery',
  templateUrl: './post-gallery.component.html',
  styleUrls: ['./post-gallery.component.css']
})
export class PostGalleryComponent implements OnInit {

  banner: any = {
    pagetitle: "Careers",
    bg_image: "1.jpg",
    title: "Careers",
  };

  recepient = {
    email: "gsparuhinog@crestcebu.com",
    contact: "(+63) 923-720-7869 (sun) / (+63) 917-894-2798 (globe)"
  };

  position = [
    {
      title: "Branch Manager",
      responsibilities: `Responsible in planning, developing and administering policies and
        programs in the branch designed to make expeditious use of the human resources.
        Oversee the implementation of the standard operational procedures,
        to ensure effective and efficient operational system and process.
        Leads and nurture a work culture that promotes dynamic communication among staff,
        stimulating teamwork to accomplish desired goals and objective`,
      qualifications: [
        `Candidate must possess at least Bachelor’s/College Degree in Business Management or its equivalent`,
        `Must have at least 2-5 years managerial/ supervisory experience in freight forwarding especially handling operational issues`,
        `Must have solid background in administrative functions`,
        `Preferably with significant exposure to accounting processes, sales and marketing`,
        `Knowledgeable in Brokerage and Customs Administration is a plus`,
        `Willing to be assigned in Malate, Manila`,
        `Full-time position available `
      ],
    },
    {
      title: "Assistant Branch Manager",
      responsibilities: "Responsible in assisting the Manager in planning, developing and administering policies and programs in the branch designed to make expeditious use of the human resources. Oversee the implementation of the standard operational procedures, to ensure effective and efficient operational system and process. Leads and nurture a work culture that promotes dynamic communication among staff, stimulating teamwork to accomplish desired goals and objective",
      qualifications: [
        `Candidate must possess at least Bachelor’s/College Degree in Business Management or its equivalent`,
        `Must have at least 2-5 years managerial/ supervisory experience in freight forwarding especially handling operational issues`,
        `Must have solid background in administrative functions`,
        `Preferably with significant exposure to accounting processes, sales and marketing`,
        `Knowledgeable in Brokerage and Customs Administration is a plus`,
        `Willing to be assigned in Malate, Manila`,
        `Full-time position available`
      ],
    },
    {
      title: "Customer Service Representative",
      responsibilities: `Responsible for providing quality and efficient customer service to customers through booking, monitoring incoming and outgoing LCL Shipments.`,
      qualifications: [
        `Candidate must possess at least Bachelor's/College Degree in Logistic/Transportation, Hospitality Management, or its equivalent`,
        `With experience in booking and account's monitoring in a freight forwarding company or shipping lines is required`,
        `Organize, keen to details and efficient in monitoring tasks`,
        `Flexible and with positive work attitude`,
        `Willing to be assigned at Navotas, Manila`,
        `Full-time position available`
      ],
    },
    {
      title: `Prime Mover Driver`,
      responsibilities: `Ensure that the tasks designated by the Operations Team Leader are carried out efficiently and effectively to attain maximum satisfaction from the customers. Ensure that goods and products are in good condition during loading and unloading`,
      qualifications: [
        `Candidate must possess at least college level or with vocational course`,
        `With professional driver's license 1238 code`,
        `Can driver/ operate prime mover`,
        `Diligent, Attention to detail and safety`,
        `Has the ability to navigate efficiently`,
        `Willing to be assigned in North Bay Boulevard and San Rafael Navotas`,
        `Full-time position available`
      ],
    },
    {
      title: `LCL/ Van Driver`,
      responsibilities: `Ensure that the tasks designated by the Operations Team Leader are carried out efficiently and effectively to attain maximum satisfaction from the customers. Ensure that goods and products are in good condition during loading and unloading`,
      qualifications: [
        `Candidate must possess at least college level or with vocational course`,
        `With professional driver's license 123 code`,
        `Can drive a four (4) wheeler vehicle and/or Wing van`,
        `Diligent, Attention to detail and safety`,
        `Has the ability to navigate efficiently`,
        `Willing to be assigned in Cebu City`,
        `Full-time position available`
      ],
    },
    {
      title: `IT Staff`,
      responsibilities: `Maintains Web environment by identifying system requirements; installing upgrades; monitoring system performance. Develop, maintain and provide technical support to employees by ensuring the smooth running of all computer systems. Troubleshoot system and network problems, diagnose and solve hardware or software accountabilities. Conduct electrical safety checks on computer equipment`,
      qualifications: [
        `Candidate must possess at least a Bachelor's/College Degree in Information Technology, Computer Science, Computer Engineering or any IT related course`,
        `Must have basic skills in Networking, Internet/ Website Management, PC Troubleshooting, PC assembly, Operating system and application software installation`,
        `Preferably with at least one (1) year experience in a freight forwarding business`,
        `Willing to be assigned in Malate Manila`,
        `Full-time position available`
      ],
    },
    {
      title: `Sales/ Accounts Executive`,
      responsibilities: `Responsible for securing and developing new clients relationships and maintaining a high level of customer service at all times. Pro-active in increasing sales by conducting sales calls and/or reservations while maintaining existing clients (both local and international). Maintain high level of integrity in every business relationship with clients, supplier and prospective customers.`,
      qualifications: [
        `Candidate must possess at least a Bachelor’s / College Degree in Logistic/Transportation,Customs Administration, Business Administration or its equivalent`,
        `Possess strong presentation, communication, organization and time-management skill`,
        `Preferably 1-4 Yrs Experienced Employee specialized in Sales - Corporate or equivalent`,
        `With pleasing personality and hardworking`,
        `Possess strong presentation, communication, organization and time-management skills`,
        `Knows how to drive 4W vehicle and with driver’s license is a plus`,
        `Willing to be assigned in Manila`,
        `Full-time position available`
      ],
    },
    {
      title: `HR OJT (Internship)`,
      responsibilities: ``,
      qualifications: [
        `Candidate should be enrolled in the internship course of their program such as: (Psychology or Human Resource Development Management)`,
        `Must have basic knowledge of HR Facets such as: (Recruitment & Training, Employee Relations, Compensation & Benefits and Procurement)`,
        `Can report for duty at lease a minimum of 4 hours a day`,
        `Willing to report at RM 203 PMM Bldg. M.J. Cuenco Ave. Mabolo, Cebu City or Unit-D Marian Bldg., 2556 G. Del Pilar St. Cor. P. Ocampo Sr., Malate Manila`,
        `No work experience required `
      ],
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
