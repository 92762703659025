<ul class=" nav navbar-nav">
  <li>
      <a routerLink="/index" (click)="closeNav()">Home</a>
  </li>
  <li>
      <a routerLink="/news" [state]="{ activePagination: 'event' }" (click)="changeFilter('Events')">News & Events</a>
  </li>
  <li>
      <a routerLink="/news" [state]="{ activePagination: 'milestone' }" (click)="changeFilter('Milestone')">Milestones</a>
  </li>
  <li>
      <a routerLink="/news" [state]="{ activePagination: 'hiring' }" (click)="changeFilter('Hiring')">Careers</a>
  </li>
</ul>