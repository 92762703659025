<div>
  This website is created by
  <a href="" title="Ben Garcia" target="_blank" rel="noopener">Ben Garcia</a>
  and
  <a href="https://vincebanzon.github.io" title="Vince Banzon" target="_blank" rel="noopener">Vince Banzon</a>
  <br><br>

  <!-- warehouse.svg -->
  <div>Icons made by <a href="https://www.flaticon.com/authors/monkik" title="monkik" target="_blank" rel="noopener">monkik</a> from <a href="https://www.flaticon.com/" title="Flaticon" target="_blank" rel="noopener">www.flaticon.com</a></div>

  <!-- deliver.svg -->
  <div>Icons made by <a href="https://www.flaticon.com/authors/srip" title="srip" target="_blank" rel="noopener">srip</a> from <a href="https://www.flaticon.com/" title="Flaticon" target="_blank" rel="noopener">www.flaticon.com</a></div>

  <!-- air-freight.svg -->
  <div>Icons made by <a href="https://www.flaticon.com/authors/nhor-phai" title="Nhor Phai" target="_blank" rel="noopener">Nhor Phai</a> from <a href="https://www.flaticon.com/" title="Flaticon" target="_blank" rel="noopener">www.flaticon.com</a></div>

  <!-- delivery-truck.svg -->
  <div>Icons made by <a href="https://www.flaticon.com/authors/bqlqn" title="bqlqn" target="_blank" rel="noopener">bqlqn</a> from <a href="https://www.flaticon.com/" title="Flaticon" target="_blank" rel="noopener">www.flaticon.com</a></div>

  <!-- consolidate.svg -->
  <div>Icons made by <a href="https://www.freepik.com" title="Freepik" target="_blank" rel="noopener">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon" target="_blank" rel="noopener">www.flaticon.com</a></div>

  <!-- cargo-ship.svg -->
  <div>Icons made by <a href="https://www.flaticon.com/authors/surang" title="surang" target="_blank" rel="noopener">surang</a> from <a href="https://www.flaticon.com/" title="Flaticon" target="_blank" rel="noopener">www.flaticon.com</a></div>

  <!-- agreement.svg -->
  <div>Icons made by <a href="https://www.flaticon.com/authors/eucalyp" title="Eucalyp" target="_blank" rel="noopener">Eucalyp</a> from <a href="https://www.flaticon.com/" title="Flaticon" target="_blank" rel="noopener">www.flaticon.com</a></div>

  <!-- diamond -->
  <div>Icons made by <a href="https://smashicons.com/" title="Smashicons" target="_blank" rel="noopener">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon" target="_blank" rel="noopener">www.flaticon.com</a></div>

  <!-- support -->
  <div>Icons made by <a href="https://www.freepik.com" title="Freepik" target="_blank" rel="noopener">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon" target="_blank" rel="noopener">www.flaticon.com</a></div>

  <!-- route -->
  <div>Icons made by <a href="https://www.freepik.com" title="Freepik" target="_blank" rel="noopener">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon" target="_blank" rel="noopener">www.flaticon.com</a></div>

  <!-- budget -->
  <div>Icons made by <a href="https://www.freepik.com" title="Freepik" target="_blank" rel="noopener">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon" target="_blank" rel="noopener">www.flaticon.com</a></div>

  <!-- calendar -->
  <a href="https://www.freepik.com/vectors/business">Business vector created by vectorjuice - www.freepik.com</a>

  <!-- milestone -->
  <a href='https://www.freepik.com/vectors/business'>Business vector created by jcomp - www.freepik.com</a>

  <!-- hiring -->
  <a href='https://www.freepik.com/vectors/business'>Business vector created by katemangostar - www.freepik.com</a>
</div>
