import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CountToModule } from 'angular-count-to';  // Counter Module
import { GoogleMapsModule } from '@angular/google-maps';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Home1Component } from './home1/home1.component';
import { Contact1Component } from './pages/contact1/contact1.component';
import { NewsGridComponent } from './blog/news-grid/news-grid.component';
import { ProjectDetailComponent } from './pages/project-detail/project-detail.component';
import { PostGalleryComponent } from './blog/post-gallery/post-gallery.component';
import { LoadingComponent } from './elements/loading/loading.component';
import { ScrollToTopComponent } from './elements/scroll-to-top/scroll-to-top.component';
import { Header1Component } from './elements/header/header1/header1.component';
import { Header2Component } from './elements/header/header2/header2.component';
import { NavLinksComponent } from './elements/nav-links/nav-links.component';
import { Footer1Component } from './elements/footer/footer1/footer1.component';
import { Slider1Component } from './elements/rev-slider/slider1/slider1.component';
import { Slider2Component } from './elements/rev-slider/slider2/slider2.component';
import { Slider3Component } from './elements/rev-slider/slider3/slider3.component';
import { Slider4Component } from './elements/rev-slider/slider4/slider4.component';
import { IconBox1Component } from './elements/icon-box/icon-box1/icon-box1.component';
import { OurWork1Component } from './elements/our-work/our-work1/our-work1.component';
import { Specialization1Component } from './elements/specialization/specialization1/specialization1.component';
import { OurTeam1Component } from './elements/our-team/our-team1/our-team1.component';
import { Blog1Component } from './elements/blog/blog1/blog1.component';
import { OurWork2Component } from './elements/our-work/our-work2/our-work2.component';
import { OurWork3Component } from './elements/our-work/our-work3/our-work3.component';
import { Banner1Component } from './elements/banners/banner1/banner1.component';

import { AdminNewsComponent } from './pages/admin-news/admin-news.component';
import { AdminLoginComponent } from './pages/admin-login/admin-login.component';
import { SafePipe } from './safe.pipe';
import { AuthGuardService } from './auth/auth-guard.service';
import { AuthService } from './auth/auth.service';
import { PwManagerService } from './auth/pw-manager.service';
import { TokenManagerService } from './auth/token-manager.service';
import { CreditsPageComponent } from './pages/credits-page/credits-page.component';

@NgModule({
  declarations: [
    AppComponent,
    Home1Component,
    Contact1Component,
    NewsGridComponent,
    ProjectDetailComponent,
    PostGalleryComponent,
    LoadingComponent,
    ScrollToTopComponent,
    Header1Component,
    Header2Component,
    NavLinksComponent,
    Footer1Component,
    Slider1Component,
    Slider2Component,
    Slider3Component,
    Slider4Component,
    IconBox1Component,
    OurWork1Component,
    Specialization1Component,
    OurTeam1Component,
    Blog1Component,
    OurWork2Component,
    OurWork3Component,
    Banner1Component,
    AdminNewsComponent,
    AdminLoginComponent,
    SafePipe,
    CreditsPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    CountToModule,
    GoogleMapsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    AuthGuardService,
    AuthService,
    PwManagerService,
    TokenManagerService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
