import { Component, OnInit } from '@angular/core';
declare  var jQuery: any;


@Component({
  selector: 'app-header1',
  templateUrl: './header1.component.html',
  styleUrls: ['./header1.component.css']
})
export class Header1Component implements OnInit {

  isMobile = false;

  constructor() { }

  ngOnInit(): void {

    if (window.screen.width < 360) { // 768px portrait
      this.isMobile = true;
    }


    (function($) {
      setTimeout(function(){
          jQuery(".menu-btn").on('click', function() {
              jQuery(".full-menu").fadeIn(500);
          });
          jQuery('.full-menu-close').on('click', function() {
              jQuery(".full-menu").fadeToggle(500);
          });
          jQuery(".sub-menu").parent('li').addClass('has-child');
          jQuery(".mega-menu").parent('li').addClass('has-child');
          jQuery("<div class='glyphicon glyphicon-plus submenu-toogle'></div>").insertAfter(".has-child > a");
          jQuery('.has-child a+.submenu-toogle').on('click', function(ev) {
            jQuery(this).next(jQuery('.sub-menu')).slideToggle('fast', function(){
              jQuery(this).parent().toggleClass('nav-active');
            });
            ev.stopPropagation();
          });

          jQuery('.has-child').on('click', function(ev) {
            jQuery(this).find(jQuery('.sub-menu')).slideToggle('fast', function(){
              jQuery(this).parent().toggleClass('nav-active');
            });
          });

          jQuery(window).on('scroll', function() {
            const scroll = jQuery(window).scrollTop();
            if (scroll >= 100) {
              jQuery(".sticky-header").addClass("color-fill");
              jQuery(".sticky-header").addClass("is-fixed");
            } else {
                jQuery(".sticky-header").removeClass("color-fill");
                jQuery(".sticky-header").removeClass("is-fixed");
            }
          });
        }, 500);
    })(jQuery);
  }

}
