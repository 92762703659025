import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-blog1',
  templateUrl: './blog1.component.html',
  styleUrls: ['./blog1.component.css']
})
export class Blog1Component implements OnInit {

  public state = 0;

  @Input() data: any;

  constructor() { }

  ngOnInit(): void {
  }

  getState() {

    switch (this.state) {
      case 0:
        return "event";
      case 1:
        return "milestone";
      case 2:
        return "hiring";
      default:
        // shouldn't reach here
        break;
    }
  }
  incrementPaginationIndex() {
    if (this.state++ === 2) {
      this.state = 0;  // ensure we reset to 0
    }
  }
}
