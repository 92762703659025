<div class="section-full small-device  p-t80 bg-gray">
  <ng-template ngFor let-sectionData [ngForOf]="data">
    <div class="container">


      <!-- TITLE START -->
      <div class="section-head text-left">
        <h4 class="text-primary text-uppercase wt-title-small">{{sectionData.section_title}}</h4>
        <h2 class="text-uppercase font-45 font-weight-500 m-b5">{{sectionData.section_heading}}</h2>
      </div>
      <!-- TITLE END -->


      <!-- IMAGE CAROUSEL START -->
      <div class="row">

        <!-- About Us 1 -->
        <div class="col-md-4 col-sm-6  col-xs-6 col-xs-100pc m-t15 m-b90">
          <div class="wt-team-one">
            <div class="wt-team-media">
              <img src="assets/images/about-us-1.png" alt="">
            </div>
            <div class="wt-team-info text-center p-tb20 p-lr50">
              <h4 class="wt-team-title text-uppercase font-weight-600">The Company</h4>
              <button class="btn" class="site-btn-effect button-md know-more" data-toggle="modal" data-target="#Company-Modal" >Know more</button>
              <!-- <p class="m-b10">{{team.role}}</p> -->
              <!-- <button name="submit" class="btn btn-success" class="site-button site-btn-effect button-lg"
                data-toggle="modal" data-target="#Default-Modal">Show modal</button> -->
            </div>
          </div>
        </div>

        <!-- About Us 2 -->
        <div class="col-md-4 col-sm-6  col-xs-6 col-xs-100pc m-t15 m-b90">
          <div class="wt-team-one">
            <div class="wt-team-media">
              <img src="assets/images/about-us-2.png" alt="">
            </div>
            <div class="wt-team-info text-center p-tb20 p-lr50">
              <h4 class="wt-team-title text-uppercase font-weight-600">Vision & Mission</h4>
              <button class="btn" class="site-btn-effect button-md know-more" data-toggle="modal" data-target="#Mission-Modal" >Know more</button>
              <!-- <p class="m-b10">{{team.role}}</p> -->
              <!-- <button name="submit" class="btn btn-success" class="site-button site-btn-effect button-lg"
                data-toggle="modal" data-target="#Default-Modal">Show modal</button> -->
            </div>
          </div>
        </div>

        <!-- About Us 3 -->
        <div class="col-md-4 col-sm-6  col-xs-6 col-xs-100pc m-t15 m-b90">
          <div class="wt-team-one">
            <div class="wt-team-media">
              <img src="assets/images/about-us-3.png" alt="">
            </div>
            <div class="wt-team-info text-center p-tb20 p-lr50">
              <h4 class="wt-team-title text-uppercase font-weight-600">Making Waves</h4>
              <button class="btn" class="site-btn-effect button-md know-more" data-toggle="modal" data-target="#Waves-Modal" >Know more</button>
              <!-- <p class="m-b10">{{team.role}}</p> -->
              <!-- <button name="submit" class="btn btn-success" class="site-button site-btn-effect button-lg"
                data-toggle="modal" data-target="#Default-Modal">Show modal</button> -->
            </div>
          </div>
        </div>
        
      </div>
      
      <!-- MODAL POPUP START -->
      <div id="Company-Modal" class="modal fade" role="dialog">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header bg-secondry">
              <button type="button" class="close" data-dismiss="modal">&times;</button>
              <h4 class="modal-title text-white">The Company</h4>
            </div>
            <div class="modal-body font-black">
              <p>With enough experience in the forwarding industry, a group of dedicated individuals agreed to join their resources and expertise to create a company that would become "The Alternative" among the existing forwarders.<br><br>
                The staff members have solid experience in domestic and international freight forwarding including its allied services like Consolidation / Deconsolidation, Brokerage, Door to Door delivery, Warehousing, Trucking, Project Cargo and Business Development, among others.<br><br>
                The staff members also believe that teamwork is one quality that keeps them together as customer satisfaction is enhanced with shared efforts for every task encountered. It is this same teamwork that motivates the group to this entrepreneurial endeavor with the belief that competition in a free enterprise could redound to better services in forwarding industry in particular, and more progress for Cebu and the Philippines in general.<br><br>
                Lofty as it may seem the company has for its vision, of serving across the seven seas, this does not deter the courage of facing such a challenge and offering its services to a wide range of clientele. Thus, with Cebu as its home base, the company is ready to serve to several destinations nationwide. An extensive worldwide network of Agents and Business Partners gives CREST the drive to compete with multinational companies apparently controlling the industry at present.<br><br>
                As CREST offers itself as the alternative, a healthy competition gives its clients the opportunity to make a choice for a better service. At CREST we say we are: Riding on the progress of Cebu for an alternative freight forwarding.</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="site-button site-btn-effect button-sm text-uppercase letter-spacing-2" data-dismiss="modal" *ngIf="statusIndex < 2"><span> Close</span></button>
            </div>
          </div>
        </div>
      </div>

      <div id="Mission-Modal" class="modal fade" role="dialog">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header bg-secondry">
              <button type="button" class="close" data-dismiss="modal">&times;</button>
              <h4 class="modal-title text-white">Vision & Mission</h4>
            </div>
            <div class="modal-body font-black">
              <p>VISION -<br>
                To serve excellent freight forwarding across the seven seas. <br>
                To provide forwarding that is beyond excellence in serving diverse clientele globally
                <br><br>
                MISSION -<br>
                To be the alternative forwarding shape up for clientele better services through experienced staff willing to go an extra mile for customer satisfaction. <br>
                To be flexible in providing services to our customer coupled with passion in every delivery experience. <br>
                To go beyond our customer’s satisfaction through alternative and cheaper means in all our services. <br>
                To rise competitively above every expectation in the forwarding business
                </p>
            </div>
            <div class="modal-footer">
              <button type="button" class="site-button site-btn-effect button-sm text-uppercase letter-spacing-2" data-dismiss="modal" *ngIf="statusIndex < 2"><span> Close</span></button>
            </div>
          </div>
        </div>
      </div>

      <div id="Waves-Modal" class="modal fade" role="dialog">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header bg-secondry">
              <button type="button" class="close" data-dismiss="modal">&times;</button>
              <h4 class="modal-title text-white">Making Waves</h4>
            </div>
            <div class="modal-body font-black">
              <p>The main asset of CREST is its people. People bringing along with them years of experience working in prestigious freight forwarding companies with global network to various destinations. Highly trained people servicing clients with years of on the job and operational experiences. <br> <br>
                Their staffs have technical expertise to give reliable and timely service to shippers of domestic and international cargoes. They work with major airlines, shipping lines, terminal operators, and truck operators ensure timely deliveries at competitive rates. Thorough import and export services are provided to international cargo shippers including those located at the Mactan Export Processing Zone. <br> <br>
                Dedication is another quality brought along by its staff after years of experiences in the industry. This provides a cutting edge for the company that aims to be the alternative. With dedication, each staff member is willing to "Go an extra mile" just to ensure that an added value is provided for every service, something that can be readily appreciated by the client. <br> <br>
                This proven track records of dedicated staff members are manifested by the continued patronage of various clients in the respective company/ies from where its staff came from before coming together to create the alternative forwarder - CREST. <br> <br>
                Together, this team will gain its momentum in forwarding industry, bringing to a new height industry standards of services, like waves gaining strength and heights as they rolls towards the shores, with the company standards consistently upheld to the CREST for every wave of task served to each and every customer. <br> <br>
                Experience, dedication, and professionalism will bring customer satisfaction to new heights at CREST.</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="site-button site-btn-effect button-sm text-uppercase letter-spacing-2" data-dismiss="modal" *ngIf="statusIndex < 2"><span> Close</span></button>
            </div>
          </div>
        </div>
      </div>
      <!-- MODAL POPUP END -->



    </div>
  </ng-template>
</div>