<div class="page-wraper"> 

      <!-- HEADER START -->
      <app-header1></app-header1>
      <!-- HEADER END -->

      <!-- CONTENT START -->
      <div class="page-content">
          <!-- SLIDER START -->
          <app-slider1></app-slider1>
          <!-- SLIDER END -->

          <!-- ABOUT COMPANY -->
          <app-icon-box1></app-icon-box1>
          <!-- ABOUT COMPANY END -->
          
          <!-- EQUIPMENTS SLIDER START -->
          <app-our-work1 [data]="equipmentsSection"></app-our-work1>
          <!-- EQUIPMENTS SLIDER END --> 

          <!-- FACILITIES SLIDER START -->
          <app-our-work1 [data]="facilitiesSection"></app-our-work1>
          <!-- FACILITIES SLIDER END --> 
                                  
          <!-- WELCOME SECTION START -->
          <app-specialization1 [data]="ourSpecializationSection"></app-specialization1>
          <!-- WELCOME  SECTION END -->

          <!-- OUR TEAM START -->
          <app-our-team1 [data]="aboutUs"></app-our-team1>
          <!-- OUR TEAM END -->

          <!-- OUR BLOG START -->
          <app-blog1 [data]="blogs"></app-blog1>
          <!-- OUR BLOG END -->

          <!-- CONTACT US SECTION -->

          <!-- INNER PAGE BANNER -->
          <app-banner1 [data]="contactus"></app-banner1>
          <!-- INNER PAGE BANNER END -->

          <div class="container">
            <br><br>
            <div class="row">

              <!-- Cebu Office -->
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="widget widget_address_outer m-b20">
                  <h4 class="widget-title">Head Office (Cebu)</h4>
                  <span class="contact-detail">Door 203, PMM Bldg. M.J. Cuenco Avenue, Mabolo, Cebu City, 6000</span>
                  <span class="contact-detail">(032) 266-0685</span>
                  <span class="contact-detail">(032) 266-0686</span>
                  <span class="contact-detail">(032) 260-2820</span>
                  <span class="contact-detail">(032) 262-3945</span>
                  <a routerLink="/contact" class="site-button-secondry site-btn-effect contact-us-btn" [state]="{ branchID: 1 }"><span>Contact Us</span></a>
                </div>
              </div>

              <!-- Manila Office -->
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="widget widget_address_outer m-b20">
                  <h4 class="widget-title">Manila Branch</h4>
                    <span class="contact-detail">Unit-D Marian Bldg., 2556 G. Del Pilar St. Cor. P. Ocampo Sr. Malate Manila</span>
                    <span class="contact-detail">(02) 254-6447</span>
                    <span class="contact-detail">(02) 523-3031</span>
                    <span class="contact-detail">(02) 523-1732</span>
                    <span class="contact-detail">(02) 879-1631</span>
                    <a routerLink="/contact" class="site-button-secondry site-btn-effect contact-us-btn" [state]="{ branchID: 2 }"><span>Contact Us</span></a>
                </div>
              </div>

              <!-- Davao Office -->
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="widget widget_address_outer m-b20">
                  <h4 class="widget-title">Davao Branch</h4>
                  <span class="contact-detail">Door 7 PGJ Realty Bldg., Brgy. Vicente Hizon Davao City, 8000</span>
                  <span class="contact-detail">(082) 392-5109</span>
                  <a routerLink="/contact" class="site-button-secondry site-btn-effect contact-us-btn" [state]="{ branchID: 3 }"><span>Contact Us</span></a>
                </div>
              </div>

              <!-- CDO Office -->
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="widget widget_address_outer m-b20">
                  <h4 class="widget-title">Cagayan De Oro Branch</h4>
                  <span class="contact-detail">Door 203, Waterside Living Complex, Julio Pacana Licoan, Cagayan de Oro City, 9000</span>
                  <span class="contact-detail">(088) 333-7126</span>
                  <a routerLink="/contact" class="site-button-secondry site-btn-effect contact-us-btn" [state]="{ branchID: 4 }"><span>Contact Us</span></a>
                </div>
              </div>
            </div>

          </div>
          <!-- CONTACT US SECTION END -->

      </div>

      <!-- CONTENT END -->

      <!-- FOOTER START -->
      <app-footer1></app-footer1>
      <!-- FOOTER END -->

      <!-- BUTTON TOP START -->
  <app-scroll-to-top></app-scroll-to-top>

  </div>

<!-- LOADING AREA START ===== -->
<app-loading></app-loading>
