<div class="section-full small-device p-t30  p-b80  bg-white">

  <div class="container">

    <!-- IMAGE CAROUSEL START -->
    <div class="section-content clearfix">
      <div class="col-md-12">
        <div class="row  no-col-gap bg-white">
          <div class="col-md-2 col-sm-6 col-xs-6 col-xs-50pc bg-white" style="height: 327px">
            <div class="wt-icon-box-wraper center p-lr5  p-b50 p-t50 bdr-1 bdr-solid bdr-gray-light hover-border-outer hover-border">
              <div class="icon-lg m-b15">
                <span class="icon-cell">
                  <i>
                    <img src="assets/images/icons/air-freight.svg" />
                  </i>
                </span>
              </div>
              <div class="icon-content text-black">
                <h4 class="wt-tilte text-uppercase font-weight-600 m-b20">Air Freight</h4>
                <hr style="border: 1px solid #08A146;">
                <p>Domestic / International forwarding</p>
              </div>
            </div>
          </div>
          <div class="col-md-2 col-sm-6 col-xs-6 col-xs-50pc bg-white" style="height: 327px">
            <div class="wt-icon-box-wraper center p-lr5  p-b50  p-t50 bdr-1 bdr-solid bdr-gray-light hover-border-outer hover-border">
              <div class="icon-lg m-b15">
                <span class="icon-cell">
                  <i>
                    <img src="assets/images/icons/cargo-ship.svg" />
                  </i>
                </span>
              </div>
              <div class="icon-content text-black">
                <h4 class="wt-tilte text-uppercase font-weight-600 m-b20">Sea Freight</h4>
                <hr style="border: 1px solid #08A146;">
                <p>Domestic / International forwarding</p>
              </div>
            </div>
          </div>
          <div class="col-md-2 col-sm-6 col-xs-6 col-xs-50pc bg-white" style="height: 327px">
            <div class="wt-icon-box-wraper center p-lr5  p-b50  p-t50 bdr-1 bdr-solid bdr-gray-light hover-border-outer hover-border">
              <div class="icon-lg m-b15">
                <span class="icon-cell">
                  <i>
                    <img src="assets/images/icons/delivery-truck.svg" />
                  </i>
                </span>
              </div>
              <div class="icon-content text-black">
                <h4 class="wt-tilte text-uppercase font-weight-600 m-b20">Trucking</h4>
                <hr style="border: 1px solid #08A146;">
                <p>Trucking Services<br>Dry Van Delivery<br>Project Cargo<br>Break Bulk</p>
              </div>
            </div>
          </div>
          <div class="col-md-2 col-sm-6 col-xs-6 col-xs-50pc bg-white" style="height: 327px">
            <div class="wt-icon-box-wraper center p-lr5  p-b50  p-t50 bdr-1 bdr-solid bdr-gray-light hover-border-outer hover-border">
              <div class="icon-lg m-b15">
                <span class="icon-cell">
                  <i>
                    <img src="assets/images/icons/warehouse.svg" />
                  </i>
                </span>
              </div>
              <div class="icon-content text-black">
                <h4 class="wt-tilte text-uppercase font-weight-600 m-b20">Storage</h4>
                <hr style="border: 1px solid #08A146;">
                <p>Warehousing</p>
              </div>
            </div>
          </div>
          <div class="col-md-2 col-sm-6 col-xs-6 col-xs-50pc bg-white" style="height: 327px">
            <div class="wt-icon-box-wraper center p-lr5  p-b50  p-t50 bdr-1 bdr-solid bdr-gray-light hover-border-outer hover-border">
              <div class="icon-lg m-b15">
                <span class="icon-cell">
                  <i>
                    <img src="assets/images/icons/consolidate.svg" />
                  </i>
                </span>
              </div>
              <div class="icon-content text-black">
                <h4 class="wt-tilte text-uppercase font-weight-600 m-b20">Consolidation</h4>
                <hr style="border: 1px solid #08A146;">
                <p>Consolidation / Deconsolidation <br> Buyer’s Consolidation </p>
              </div>
            </div>
          </div>
          <div class="col-md-2 col-sm-6 col-xs-6 col-xs-50pc bg-white" style="height: 327px">
            <div class="wt-icon-box-wraper center p-lr5  p-b50  p-t50 bdr-1 bdr-solid bdr-gray-light hover-border-outer hover-border">
              <div class="icon-lg text-primary m-b15">
                <span class="icon-cell ">
                  <i class="">
                    <img src="assets/images/icons/agreement.svg" />
                  </i>
                </span>
              </div>
              <div class="icon-content text-black">
                <h4 class="wt-tilte text-uppercase font-weight-600 m-b20">Brokerage</h4>
                <hr style="border: 1px solid #08A146;">
                <p> Customs Clearance <br> Importer Customs Accreditation </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>