<header class="site-header header-style-1  nav-wide">
    <div class="sticky-header main-bar-wraper">
        <div class="main-bar bg-white p-t10">
            <div class="container">
                <div class="logo-header">
                    <div class="logo-header-inner logo-header-one">
                        <a routerLink="/pages/admin">
                            <img src="assets/images/logo-navbar.png" width="81" height="55" alt="Crest Forwarder Inc." />
                        </a>
                    </div>
                </div>
                <div class="extra-nav header-2-nav">
                    <div class="extra-cell">
                        <a role="button" (click)="onLogout()" class="site-search-btn">Logout<i class="fa fa-sign-out text-primary m-l10"></i></a>
                    </div>
                </div>
                <div class="header-nav nav-animation navbar-collapse collapse">
                    <ul class=" nav navbar-nav">
                        <li>
                            <a routerLink="#">CrestCebu Website Administrator</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>