<div id="welcome_wrapper" class="rev_slider_wrapper fullscreen-container" data-alias="goodnews-header" data-source="gallery" style="background:#eeeeee;padding:0px;">
  <div id="welcome" class="rev_slider fullscreenbanner" style="display:none;" data-version="5.4.3.1">
      <ul>	
      
          <!-- SLIDE 1 -->
          <li data-index="rs-901" 
          data-transition="fade" 
          data-slotamount="default" 
          data-hideafterloop="0" 
          data-hideslideonmobile="off"  
          data-easein="default" 
          data-easeout="default" 
          data-masterspeed="default"  
          data-thumb="assets/images/main-slider/slider1/slide1.jpg"  
          data-rotate="0"  
          data-fstransition="fade" 
          data-fsmasterspeed="300" 
          data-fsslotamount="7" 
          data-saveperformance="off"  
          data-title="Slide Title" 
          data-param1="Additional Text" 
          data-param2="" 
          data-param3="" 
          data-param4="" 
          data-param5="" 
          data-param6="" 
          data-param7="" 
          data-param8="" 
          data-param9="" 
          data-param10="" 
          data-description="">
              <!-- MAIN IMAGE -->
              <img src="assets/images/main-slider/slider1/slide1.jpg"  alt=""  data-lazyload="assets/images/main-slider/slider1/slide1.jpg" data-bgposition="center center" 
              data-bgfit="cover" data-bgparallax="4" class="rev-slidebg" data-no-retina>
              <!-- LAYERS -->
              <!-- LAYER NR. 1 [ for overlay ] -->
              <div class="tp-caption tp-shape tp-shapewrapper " 
              id="slide-901-layer-0" 
              data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" 
              data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" 
              data-width="full"
              data-height="full"
              data-whitespace="nowrap"
              data-type="shape" 
              data-basealign="slide" 
              data-responsive_offset="off" 
              data-responsive="off"
              data-frames='[
              {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
              ]'
              data-textAlign="['left','left','left','left']"
              data-paddingtop="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
              
              style="z-index: 1;background-color:rgba(0, 0, 0, 0.5);border-color:rgba(0, 0, 0, 0);border-width:0px;"> 
              </div>
             
              <!-- LAYER NR. 3 [ Black Box ] -->
              <div class="tp-caption   tp-resizeme" 
              id="slide-901-layer-3" 
              data-x="['left','left','left','left']" data-hoffset="['50','50','30','30']" 
              data-y="['top','top','top','top']" data-voffset="['200','200','200','200']"  
              data-fontsize="['20','20','20','20']"
              data-lineheight="['56','56','56','50']"
              data-width="['700','700','96%','96%']"
              data-height="['none','none','none','none']"
              data-whitespace="['normal','normal','normal','normal']"
          
              data-type="text" 
              data-responsive_offset="on" 
              data-frames='[
              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
              ]'
              data-textAlign="['left','left','left','left']"
              data-paddingtop="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
          
              style="z-index: 13; 
              white-space: normal; 
              font-weight: 600;
              color:#fff;
              border-width:0px;letter-spacing:5px">
              <div class="text-primary text-uppercase">We Make Sure</div>
              </div>
              
              
                                          
              <!-- LAYER NR. 4 [ for title ] -->
              <div class="tp-caption   tp-resizeme" 
              id="slide-901-layer-4" 
              data-x="['left','left','left','left']" data-hoffset="['50','50','30','30']" 
              data-y="['top','top','top','top']" data-voffset="['250','250','250','250']"  
              data-fontsize="['56','56','38','28']"
              data-lineheight="['66','66','48','38']"
              data-width="['600','600','85%','85%']"
              data-height="['none','none','none','none']"
              data-whitespace="['normal','normal','normal','normal']"
          
              data-type="text" 
              data-responsive_offset="on" 
              data-frames='[
              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
              ]'
              data-textAlign="['left','left','left','left']"
              data-paddingtop="[5,5,5,5]"
              data-paddingright="[0,0,0,0]"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
          
              style="z-index: 13; 
              white-space: normal; 
              font-weight: 900;
              color:#fff;
              border-width:0px;">
              <div style="font-family: 'Oswald', sans-serif;">
                  <span class="text-white">Committed to superior quality and results.</span>
              </div>
              
              </div>
          
              <!-- LAYER NR. 5 [ for paragraph] -->
              <div class="tp-caption  tp-resizeme" 
              id="slide-901-layer-5" 
              data-x="['left','left','left','left']" data-hoffset="['50','50','30','30']" 
              data-y="['top','top','top','top']" data-voffset="['410','410','380','350']"  
              data-fontsize="['16','16','16','14']"
              data-lineheight="['30','30','30','22']"
              data-width="['600','600','90%','90%']"
              data-height="['none','none','none','none']"
              data-whitespace="['normal','normal','normal','normal']"
          
              data-type="text" 
              data-responsive_offset="on"
              data-frames='[
              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
              ]'
              data-textAlign="['left','left','left','left']"
              data-paddingtop="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
          
              style="z-index: 13; 
              font-weight: 500; 
              color:#fff;
              border-width:0px;">
              <span style="font-family: 'Roboto', sans-serif">Lorem Ipsum is simply dummy text of the printing and type setting industry. lorem Ipsum has been end.</span>
              </div>
          
              <!-- LAYER NR. 6 [ for see all service botton ] -->
              <div class="tp-caption tp-resizeme" 	
              id="slide-901-layer-6"						
              data-x="['left','left','left','left']" data-hoffset="['50','50','30','30']" 

              data-y="['top','top','top','top']" data-voffset="['500','500','480','430']"  
              data-lineheight="['none','none','none','none']"
              data-width="['300','300','300','300']"
              data-height="['none','none','none','none']"
              data-whitespace="['normal','normal','normal','normal']"
              
              data-type="text" 
              data-responsive_offset="on"
              data-frames='[ 
              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
              ]'
              data-textAlign="['left','left','left','left']"
              data-paddingtop="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
              
              style="z-index:13; text-transform:uppercase;">
              <a routerLink="/about-1" class="site-button site-btn-effect" style="font-weight:600;font-family: 'Roboto', sans-serif">Read More</a>
              </div>
          </li>
          
          <!-- SLIDE 2 -->
          <li data-index="rs-902" 
          data-transition="fade" 
          data-slotamount="default" 
          data-hideafterloop="0" 
          data-hideslideonmobile="off"  
          data-easein="default" 
          data-easeout="default" 
          data-masterspeed="default"  
          data-thumb="assets/images/main-slider/slider1/slide2.jpg"  
          data-rotate="0"  
          data-fstransition="fade" 
          data-fsmasterspeed="300" 
          data-fsslotamount="7" 
          data-saveperformance="off"  
          data-title="Click" 
          data-param1="" 
          data-param2="" 
          data-param3="" 
          data-param4="" 
          data-param5="" 
          data-param6="" 
          data-param7="" 
          data-param8="" 
          data-param9="" 
          data-param10="" 
          data-description="">
              <!-- MAIN IMAGE -->
              <img src="assets/images/main-slider/slider1/slide2.jpg"  alt=""  data-lazyload="assets/images/main-slider/slider1/slide2.jpg" data-bgposition="center center" 
              data-bgfit="cover" data-bgparallax="4" class="rev-slidebg" data-no-retina>
              <!-- LAYERS -->
              <!-- LAYER NR. 1 [ for overlay ] -->
              <div class="tp-caption tp-shape tp-shapewrapper " 
              id="slide-902-layer-0" 
              data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" 
              data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" 
              data-width="full"
              data-height="full"
              data-whitespace="nowrap"
              data-type="shape" 
              data-basealign="slide" 
              data-responsive_offset="off" 
              data-responsive="off"
              data-frames='[
              {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
              ]'
              data-textAlign="['left','left','left','left']"
              data-paddingtop="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
              
              style="z-index: 1;background-color:rgba(0, 0, 0, 0.5);border-color:rgba(0, 0, 0, 0);border-width:0px;"> 
              </div>
             
              <!-- LAYER NR. 3 [ Black Box ] -->
              <div class="tp-caption   tp-resizeme" 
              id="slide-902-layer-3" 
              data-x="['left','left','left','left']" data-hoffset="['50','50','30','30']" 
              data-y="['top','top','top','top']" data-voffset="['200','200','200','200']"  
              data-fontsize="['20','20','20','20']"
              data-lineheight="['56','56','56','50']"
              data-width="['700','700','96%','96%']"
              data-height="['none','none','none','none']"
              data-whitespace="['normal','normal','normal','normal']"
          
              data-type="text" 
              data-responsive_offset="on" 
              data-frames='[
              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
              ]'
              data-textAlign="['left','left','left','left']"
              data-paddingtop="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
          
              style="z-index: 13; 
              white-space: normal; 
              font-weight: 600;
              color:#fff;
              border-width:0px;letter-spacing:5px">
              <div class="text-primary text-uppercase">We Make Sure</div>
              </div>
              
              
                                          
              <!-- LAYER NR. 4 [ for title ] -->
              <div class="tp-caption   tp-resizeme" 
              id="slide-902-layer-4" 
              data-x="['left','left','left','left']" data-hoffset="['50','50','30','30']" 
              data-y="['top','top','top','top']" data-voffset="['250','250','250','250']"  
              data-fontsize="['56','56','38','28']"
              data-lineheight="['66','66','48','38']"
              data-width="['600','600','85%','85%']"
              data-height="['none','none','none','none']"
              data-whitespace="['normal','normal','normal','normal']"
          
              data-type="text" 
              data-responsive_offset="on" 
              data-frames='[
              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
              ]'
              data-textAlign="['left','left','left','left']"
              data-paddingtop="[5,5,5,5]"
              data-paddingright="[0,0,0,0]"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
          
              style="z-index: 13; 
              white-space: normal; 
              font-weight: 900;
              color:#fff;
              border-width:0px;">
              <div style="font-family: 'Oswald', sans-serif;">
                  <span class="text-white"> Engineering your dreams with us.</span>
              </div>
              
              </div>
          
              <!-- LAYER NR. 5 [ for paragraph] -->
              <div class="tp-caption  tp-resizeme" 
              id="slide-902-layer-5" 
              data-x="['left','left','left','left']" data-hoffset="['50','50','30','30']" 
              data-y="['top','top','top','top']" data-voffset="['410','410','380','350']"  
              data-fontsize="['16','16','16','14']"
              data-lineheight="['30','30','30','22']"
              data-width="['600','600','90%','90%']"
              data-height="['none','none','none','none']"
              data-whitespace="['normal','normal','normal','normal']"
          
              data-type="text" 
              data-responsive_offset="on"
              data-frames='[
              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
              ]'
              data-textAlign="['left','left','left','left']"
              data-paddingtop="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
          
              style="z-index: 13; 
              font-weight: 500; 
              color:#fff;
              border-width:0px;">
              <span style="font-family: 'Roboto', sans-serif">Lorem Ipsum is simply dummy text of the printing and type setting industry. lorem Ipsum has been end.</span>
              </div>
          
              <!-- LAYER NR. 6 [ for see all service botton ] -->
              <div class="tp-caption tp-resizeme" 	
              id="slide-902-layer-6"						
              data-x="['left','left','left','left']" data-hoffset="['50','50','30','30']" 

              data-y="['top','top','top','top']" data-voffset="['500','500','480','430']"  
              data-lineheight="['none','none','none','none']"
              data-width="['300','300','300','300']"
              data-height="['none','none','none','none']"
              data-whitespace="['normal','normal','normal','normal']"
              
              data-type="text" 
              data-responsive_offset="on"
              data-frames='[ 
              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
              ]'
              data-textAlign="['left','left','left','left']"
              data-paddingtop="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
              
              style="z-index:13; text-transform:uppercase;">
              <a routerLink="/about-1" class="site-button site-btn-effect" style="font-weight:600;font-family: 'Roboto', sans-serif">Read More</a>
              </div>

              

          </li> 
          
          <!-- SLIDE 2 -->
          <li data-index="rs-903" 
          data-transition="fade" 
          data-slotamount="default" 
          data-hideafterloop="0" 
          data-hideslideonmobile="off"  
          data-easein="default" 
          data-easeout="default" 
          data-masterspeed="default"  
          data-thumb="assets/images/main-slider/slider1/slide3.jpg"  
          data-rotate="0"  
          data-fstransition="fade" 
          data-fsmasterspeed="300" 
          data-fsslotamount="7" 
          data-saveperformance="off"  
          data-title="Click" 
          data-param1="" 
          data-param2="" 
          data-param3="" 
          data-param4="" 
          data-param5="" 
          data-param6="" 
          data-param7="" 
          data-param8="" 
          data-param9="" 
          data-param10="" 
          data-description="">
              <!-- MAIN IMAGE -->
              <img src="assets/images/main-slider/slider1/slide3.jpg"  alt=""  data-lazyload="assets/images/main-slider/slider1/slide3.jpg" data-bgposition="center center" 
              data-bgfit="cover" data-bgparallax="4" class="rev-slidebg" data-no-retina>
              <!-- LAYERS -->
              <!-- LAYER NR. 1 [ for overlay ] -->
              <div class="tp-caption tp-shape tp-shapewrapper " 
              id="slide-903-layer-0" 
              data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" 
              data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" 
              data-width="full"
              data-height="full"
              data-whitespace="nowrap"
              data-type="shape" 
              data-basealign="slide" 
              data-responsive_offset="off" 
              data-responsive="off"
              data-frames='[
              {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
              ]'
              data-textAlign="['left','left','left','left']"
              data-paddingtop="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
              
              style="z-index: 1;background-color:rgba(0, 0, 0, 0.5);border-color:rgba(0, 0, 0, 0);border-width:0px;"> 
              </div>
             
              <!-- LAYER NR. 3 [ Black Box ] -->
              <div class="tp-caption   tp-resizeme" 
              id="slide-903-layer-3" 
              data-x="['left','left','left','left']" data-hoffset="['50','50','30','30']" 
              data-y="['top','top','top','top']" data-voffset="['200','200','200','200']"  
              data-fontsize="['20','20','20','20']"
              data-lineheight="['56','56','56','50']"
              data-width="['700','700','96%','96%']"
              data-height="['none','none','none','none']"
              data-whitespace="['normal','normal','normal','normal']"
          
              data-type="text" 
              data-responsive_offset="on" 
              data-frames='[
              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
              ]'
              data-textAlign="['left','left','left','left']"
              data-paddingtop="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
          
              style="z-index: 13; 
              white-space: normal; 
              font-weight: 600;
              color:#fff;
              border-width:0px; letter-spacing:5px">
              <div class="text-primary text-uppercase ">We Make Sure</div>
              </div>
              
              
                                          
              <!-- LAYER NR. 4 [ for title ] -->
              <div class="tp-caption   tp-resizeme" 
              id="slide-903-layer-4" 
              data-x="['left','left','left','left']" data-hoffset="['50','50','30','30']" 
              data-y="['top','top','top','top']" data-voffset="['250','250','250','250']"  
              data-fontsize="['56','56','38','28']"
              data-lineheight="['66','66','48','38']"
              data-width="['600','600','85%','85%']"
              data-height="['none','none','none','none']"
              data-whitespace="['normal','normal','normal','normal']"
          
              data-type="text" 
              data-responsive_offset="on" 
              data-frames='[
              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
              ]'
              data-textAlign="['left','left','left','left']"
              data-paddingtop="[5,5,5,5]"
              data-paddingright="[0,0,0,0]"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
          
              style="z-index: 13; 
              white-space: normal; 
              font-weight: 900;
              color:#fff;
              border-width:0px;">
              <div style="font-family: 'Oswald', sans-serif;">
                  <span class="text-white">The rest put the con in contractor!</span>
              </div>
              
              </div>
          
              <!-- LAYER NR. 5 [ for paragraph] -->
              <div class="tp-caption  tp-resizeme" 
              id="slide-903-layer-5" 
              data-x="['left','left','left','left']" data-hoffset="['50','50','30','30']" 
              data-y="['top','top','top','top']" data-voffset="['410','410','380','350']"  
              data-fontsize="['16','16','16','14']"
              data-lineheight="['30','30','30','22']"
              data-width="['600','600','90%','90%']"
              data-height="['none','none','none','none']"
              data-whitespace="['normal','normal','normal','normal']"
          

              data-type="text" 
              data-responsive_offset="on"
              data-frames='[
              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
              ]'
              data-textAlign="['left','left','left','left']"
              data-paddingtop="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
          
              style="z-index: 13; 
              font-weight: 500; 
              color:#fff;
              border-width:0px;">
              <span style="font-family: 'Roboto', sans-serif">Lorem Ipsum is simply dummy text of the printing and type setting industry. lorem Ipsum has been end.</span>
              </div>
          
              <!-- LAYER NR. 6 [ for see all service botton ] -->
              <div class="tp-caption tp-resizeme" 	
              id="slide-903-layer-6"						
              data-x="['left','left','left','left']" data-hoffset="['50','50','30','30']" 

              data-y="['top','top','top','top']" data-voffset="['500','500','480','430']"  
              data-lineheight="['none','none','none','none']"
              data-width="['300','300','300','300']"
              data-height="['none','none','none','none']"
              data-whitespace="['normal','normal','normal','normal']"
              
              data-type="text" 
              data-responsive_offset="on"
              data-frames='[ 
              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
              ]'
              data-textAlign="['left','left','left','left']"
              data-paddingtop="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
              
              style="z-index:13; text-transform:uppercase;">
              <a routerLink="/about-1" class="site-button site-btn-effect" style="font-weight:600;font-family: 'Roboto', sans-serif">Read More</a>
              </div>

              

          </li>                                                  
           
      </ul>
      <div class="tp-bannertimer tp-bottom" style="visibility: hidden !important;"></div>	
  </div>
</div>