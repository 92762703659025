import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

declare var jQuery: any;

type NewsDetails = {
  title: string;
  category: string;
  post_title: string;
  post_date: string;
  subtitle: string;
  cover: string;
  message: string;
  images: string;
};

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.css']
})

export class ProjectDetailComponent implements OnInit {
  private url = 'https://beta.crestcebu.com/news-details-query.php';  // update me!
  newsId = "";
  banner: any = {
    pagetitle: "News and Events",
    bg_image: "5.jpg",
    title: "Outing",
  };

  event: any = {
    meta: [
      {
        name: "date",
        value: "February 16, 2015"
      }
    ],
    social: {
      facebook: "https://www.facebook.com/crestforwarderinc",
      twitter: "https://twitter.com/CrestForwarder",
      youtube: "https://www.youtube.com/channel/UC5ZUyXNt6IJZlKVpp1abnrw"
    },
    title: "CELEBRA YEARS OF EXISTENCE",
    subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,",
    message: `On the second day of our anniversary, it was held at Cebu City Sports Club. We had a small program, and each department showed their utmost talents. 10 years of challenges, trials, happiness, joy and love, still Crest is still standing and had weathered the storm, ready to serve our dearest client, as we serve across the seven seas. The main highlight of the event was the speech of Sir Dennis aka DAP, as he said “Sacrifice, is the word for success, and helping each other is our victory”, a very fruitful word from our President. As we have celebrated our anniversary, it was also the anniversary of the lovely couple of Crest, Ma’am DGP and Sir DAP. Also during that day, three of the BOD’s (DAP, VST & DGP) celebrated their 10th year of service in CREST A company that nurtures relationship and preserves the gift of life, love and family. In my months of existence in CREST, I truly salute our BOD’s for their kindness to its people, as they see their people as their asset in the company. A family that works together stays as strong as forever. One team, one goal, One Family. -"Ma.Arjiele "Jelay" Sevilla"`,
    cover: "assets/images/gallery/pic1.jpg",
    images: [
      "assets/images/gallery/portrait/pic1.jpg",
      "assets/images/gallery/portrait/pic6.jpg",
      "assets/images/gallery/portrait/pic2.jpg",
      "assets/images/gallery/pic7.jpg",
      "assets/images/gallery/portrait/pic3.jpg",
      "assets/images/gallery/pic8.jpg",
      "assets/images/gallery/portrait/pic4.jpg",
      "assets/images/gallery/pic9.jpg",
      "assets/images/gallery/portrait/pic5.jpg",
      "assets/images/gallery/pic1.jpg",
      "assets/images/gallery/pic2.jpg"
    ]
  };

  constructor(private route: ActivatedRoute, private httpClient: HttpClient) { }

  queryNewsDetails() {
    this.httpClient.post(this.url, JSON.stringify(this.newsId)).subscribe(
      (res: NewsDetails[])  => {
        this.event.title = res[0].title;
        this.event.meta[0].value =  res[0].post_date;
        this.event.subtitle = res[0].subtitle;
        this.event.cover = res[0].cover;
        this.event.message = res[0].message;
        // go through images
        this.event.images = res[0].images.split('|');
        this.banner.pagetitle = res[0].category.charAt(0).toUpperCase() + res[0].category.slice(1);
        this.banner.title = res[0].post_title;
      }
    );
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const keyId = 'id'; // created local var to resolve lint issue
      this.newsId = params[keyId];
    });
    this.queryNewsDetails();
    (function($) {
      setTimeout(function() {
        const $container = jQuery('.portfolio-wrap');
        $container.isotope({
          itemSelector: '.masonry-item',
          transitionDuration: '1s',
          originLeft: true,
          /* stamp: '.stamp' */
        });

        jQuery('.masonry-filter li').on('click', function() {
          const selector = jQuery(this).find("a").attr('data-filter');
          jQuery('.masonry-filter li').removeClass('active');
          jQuery(this).addClass('active');
          $container.isotope({ filter: selector });
          return false;
        });

      }, 1000);
    })(jQuery);

  }
}
