<footer class="site-footer footer-large  footer-light	footer-wide text-black">

  <!-- FOOTER BLOCKES START -->
  <div class="footer-top overlay-wraper">
    <div class="overlay-main"></div>

    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <H2>Crest Forwarder Inc.</H2>
        </div>
      </div>
      <div class="row">

        <!-- ABOUT COMPANY -->
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="widget widget_about">
            <p>We, the staff members of CREST,
              do believe that life is God’s most precious gift to man,
              that man must appreciate this gift,
              by nurturing life with every blessings of God’s love,
              that every blessing of God’s love,
              must be shared to every fellowman,
              thru work and by the fruits of his labor,
              so that one’s presence may enhance the life of the other,
              thus further fulfilling the realization
              of God’s Love to everyone.</p>

            <!-- Social Media Links -->
            <ul class="social-icons f-social-link">
              <li><a href="https://www.facebook.com/crestforwarderinc" class="fa fa-facebook"></a></li>
              <li><a href="https://www.youtube.com/channel/UC5ZUyXNt6IJZlKVpp1abnrw" class="fa fa-youtube"></a></li>
              <li><a href="https://twitter.com/CrestForwarder" class="fa fa-twitter"></a></li>
            </ul>
          </div>

        </div>

        <!-- USEFUL LINKS -->
        <!-- <div class="col-lg-3 col-md-3 col-sm-4">
          <div class="widget widget_services inline-links">
            <h4 class="widget-title">Useful links</h4>
            <ul>
              <li><a routerLink="/news">News & Events</a></li>
              <li><a routerLink="/careers">Careers</a></li>
            </ul>
          </div>
        </div> -->

      </div>

      <div class="m-b10">
        <div class="wt-divider bg-gray-dark"><i class="icon-dot c-square"></i></div>
      </div>
    </div>
  </div>

  <!-- FOOTER COPYRIGHT -->
  <div class="footer-bottom overlay-wraper">
    <div class="overlay-main"></div>
    <div class="container">
      <div class="row">
        <div class="wt-footer-bot-center">
          <span class="copyrights-text">© 2021 Crest Forwarder Inc. Designed By Ben & Vince (<a
              href="/credits">Credits</a>)</span>
        </div>
      </div>
    </div>
  </div>
</footer>